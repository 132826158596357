import React, { useState } from 'react'

 const CreateFromQuot = ({ isOpen, onClose, children, QuotId }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white rounded-lg p-8 w-1/4">
            <div className="flex justify-end">
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                X
              </button>
            </div>
            {children}
          </div>
          
        </div>
      )}
    </>
  )
}

export default CreateFromQuot;