import { createSlice, createAsyncThunk, createAction  } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../../apiConfig";

const username = '11160922';
const password = '60-dayfreetrial';
const encodedCredentials = btoa(`${username}:${password}`);

export const AuthinticateUser = createAsyncThunk('data/LoginUser', async (userLogin) => {
  const response = await axios.post(`${API_BASE_URL}User/Login`, userLogin, {
     method:'post',
      headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
      }
  });
  return response.data;
});


export const UseLogOut = createAction('auth/useLogOut');

const userLoginSlice = createSlice({
    name: 'Login',
    initialState: { data: {}, loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(AuthinticateUser.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(AuthinticateUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;        
      })
      .addCase(AuthinticateUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(UseLogOut, (state) => {
        state.data = {};
      });    
  },
});

export const selectUsers = (state) => state.user.data;
export default userLoginSlice.reducer;