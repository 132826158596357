import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page, Text, View, StyleSheet,Image, PDFViewer } from '@react-pdf/renderer';

import { fetchProjectById } from '../../../redux/Slice/projectSlicePDF';    
import { useParams } from 'react-router-dom';



// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20
    },
    section: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    heading: {
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    text: {
        fontSize: 10,
        marginBottom: 10
    },
    boldText: {
        fontWeight: 'bolder',
        color: '#8A8381'

    },
    wd: {
        width: 300
    },
    hr: {
        borderBottomWidth: 1,
        borderColor: 'black',
        width: '100%',
        marginBottom: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        flexDirection: 'row'
    },
    headRow: {
        backgroundColor: "#F2F2F2",
        color: '#333333',
        fontWeight: 'bold',
        fontSize: 12,
    },
    tableCol1: {
        width: '4%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol2: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol3: {
        width: '42%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol4: {
        width: '8%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },    
    headRowDiscount: {
        backgroundColor: "#F5F5F5",
        color: '#333333',
        fontWeight: 'bold',
        fontSize: 12,
    },
    discount: {
        width: '64%',
        borderStyle: 'solid',
        borderBottom: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: 'left',
        backgroundColor: "white",
        height: 25
    },
    
    discountText: {
        width: '18%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    tableColPT: { 
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: 'left',
     },
     sectionNote: {
        marginBottom: 10,
      },
     headSec: {
        marginBottom: 5,        
      },
      NoteHead: {        
        fontWeight: 'bold',
        fontSize: 14,
        textDecoration: 'underline'
      },
      notes: {
        marginTop:3,
        marginLeft:5,
        fontSize: 10,
      },
      paymentTermSec:{
        fontWeight: 'bold',
        fontSize: 10        
    },
    image: {
        width: '100%',
        height: 100,
    },
    footer: {
        textAlign: 'center',
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
    },
    YS:{
        fontWeight: 'bold',
        fontSize: 8   
    },
    YSote: {        
        fontWeight: 'bold',
        fontSize: 10,       
      },
      title:{       
        fontWeight: 'bold',
        color: '#040720',       
        marginTop: 5,
        marginBottom: 5,
        marginLeft:5,
        fontSize: 12
      },
      titleView :{
        backgroundColor: '#E8E8E8'
      },
      thanks :{
        color: 'black'
      }

});


const ProjectPDFGenerator = () => {

    const params = useParams();
    const fercoHeadingImage = require('../../../asset/images/fercoheadig.jpeg');
    const fercoFooterImage = require('../../../asset/images/fercofooter.jpeg');

    const dispatch = useDispatch();
    const [customer, setCutomer] = useState({})
    const [employee, setemployee] = useState({})
    const [masters, setMasters] = useState([])
    const [ProjectId, setProjectId] = useState(params.ProjectId);     
    const { ProjectPDF, loading, error } = useSelector((state) => state.ProjectPDF);    

    useEffect(() => {
        if (ProjectPDF.customer != null) {
            setCutomer(ProjectPDF.customer);
            setemployee(ProjectPDF.employee);
            setMasters(ProjectPDF.projectsDaataMaster);
        }
    }, [ProjectPDF])

    useEffect(() => {       
        if(ProjectId)
        {
            dispatch(fetchProjectById(ProjectId));
        }
    }, [dispatch, ProjectId]);


    if (loading === 'loading') {
        return <p>Loading...</p>;
      }
    
      if (loading === 'failed') {
        return <p>Error: {error}</p>;
      }

      console.log(ProjectPDF);
   
    return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                    <Image src={fercoHeadingImage} style={styles.image} />
                    </View>

                    <Text style={styles.heading}>Project</Text>
                    <View style={styles.hr} ></View>
                    <View style={styles.section}>
                        {/* Customer Section */}
                        <View>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Customer    :</Text> {customer.cusName}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]} >Address      :</Text> {customer.cusAddress}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]} >Attention To:</Text> {customer.cusAttentionTo}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]} >Email          :</Text> {customer.cusContactEmail}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]} >Contact Ph :</Text> {customer.cusContactNumer}</Text>
                        </View>

                        {/* Salesperson Section */}
                        <View>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Date           : </Text>{new Date(ProjectPDF.projectDate).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })} </Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Project Ref : </Text> {ProjectPDF.porjectRefNo}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Quot Ref    : </Text> {ProjectPDF.quotationRefNo}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Quot Rev    : </Text> {ProjectPDF.revNo}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Sales          : </Text> {employee && (`${employee.firstName} ${employee.lastName}`)}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Email          : </Text> {employee && employee.email}</Text>
                        </View>
                    </View>
                    <View style={styles.hr}></View>  
                        <View style={styles.section}>
                            <View>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Plan Start Date  :</Text>{new Date(ProjectPDF.planStartDate).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Plan Finish Date  :</Text> {new Date(ProjectPDF.planFinishDate).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                            </View>
                            <View>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Actual Start Date  :</Text> {new Date(ProjectPDF.actualStartDate).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Actual Finish Date  :</Text> {ProjectPDF.actualFinishDate && new Date(ProjectPDF.actualFinishDate).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                            </View>
                        </View>
                        <View style={styles.hr}></View>
                        <View style={styles.section}>
                        {/* Customer Section */}
                        <View>
                            <Text style={styles.text}><Text style={[styles.boldText]}>Project Name  :</Text> {ProjectPDF.projectName}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText]} >Scope of work :</Text> {ProjectPDF.scopeOfWork}</Text>
                            <Text style={styles.text}><Text style={[styles.boldText, styles.thanks]} >We thank you for your valuable inquiry and pleased to provide our proposal for your prestigious project as follows:</Text></Text>
                        </View>
                        </View>

                    <View style={styles.section}>
                        <View style={styles.table}>
                            <View style={{ ...styles.tableRow, ...styles.headRow }}>
                                <View style={styles.tableCol1}>
                                    <Text style={styles.tableCell}>SNo</Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>Shutter No</Text>
                                </View>
                                <View style={styles.tableCol3}>
                                    <Text style={styles.tableCell}>Description</Text>
                                </View>
                                <View style={styles.tableCol4}>
                                    <Text style={styles.tableCell}>Height</Text>
                                </View>
                                <View style={styles.tableCol4}>
                                    <Text style={styles.tableCell}>Width</Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>Unit Cast</Text>
                                </View>
                                <View style={styles.tableCol4}>
                                    <Text style={styles.tableCell}>QTY</Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>Total Cast</Text>
                                </View>
                            </View>

                            {/* Body */}

                            {masters && (
                                masters.map(master => (
                                    <View>
                                        <View style={[styles.tableRow, styles.titleView ]}>
                                            <View style={[styles.tableColPT, { textAlign: 'left' }]}>
                                            <Text style={[styles.title]}>{master.title}</Text>
                                            </View>
                                        </View>
                                        {master.rows.map((row, index) => (
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol1}>
                                                <Text style={styles.tableCell}>{index+1}</Text>
                                            </View>
                                            <View style={styles.tableCol2}>
                                                <Text style={styles.tableCell}>{row.shutterNo}</Text>
                                            </View>
                                            <View style={[styles.tableCol3,{ Wrap: 'wrap'}]}>
                                                <Text style={{...styles.tableCell, paddingLeft:1.5, paddingRight:5}}>{row.description}</Text>
                                            </View>
                                            <View style={styles.tableCol4}>
                                                <Text style={styles.tableCell}>{row.hight}</Text>
                                            </View>
                                            <View style={styles.tableCol4}>
                                                <Text style={styles.tableCell}>{row.width}</Text>
                                            </View>
                                            <View style={styles.tableCol2}>
                                                <Text style={styles.tableCell}>{row.unitCast}</Text>
                                            </View>

                                            <View style={styles.tableCol4}>
                                                <Text style={styles.tableCell}>{row.qty}</Text>
                                            </View>
                                            <View style={styles.tableCol2}>
                                                <Text style={styles.tableCell}>{row.totalCast}</Text>
                                            </View>
                                        </View>
                                        ))}
                                    </View>
                                ))
                            )}
                            {/* END Body */}

                            <View style={{ ...styles.tableRow, ...styles.headRowDiscount }}>
                                <View style={styles.discount}>
                                    <Text style={styles.tableCell}></Text>
                                </View>
                                <View style={styles.discountText}>
                                    <Text style={styles.tableCell}>Total Discount:</Text>
                                </View>
                                <View style={styles.discountText}>
                                    <Text style={styles.tableCell}>{ProjectPDF.totalDisCount}</Text>
                                </View>
                            </View>

                            <View style={{ ...styles.tableRow, ...styles.headRowDiscount }}>
                                <View style={styles.discount}>
                                    <Text style={styles.tableCell}></Text>
                                </View>
                                <View style={styles.discountText}>
                                    <Text style={styles.tableCell}>Grand Total:</Text>
                                </View>
                                <View style={styles.discountText}>
                                    <Text style={styles.tableCell}>{ProjectPDF.grandTotal}</Text>
                                </View>
                            </View>

                        </View>
                    </View>

                    <View style={styles.sectionNote}>
                        {ProjectPDF.projectHeadingsVMs && (
                            ProjectPDF.projectHeadingsVMs.map((head, index) => (
                                <View key={index} style={styles.headSec}>
                                    <Text style={styles.NoteHead}>{head.type}</Text>
                                    <Text style={styles.notes}>{head.notes}</Text>
                                </View>
                            ))
                        )}
                    </View>

                    <View style={styles.sectionNote}>
                        <View style={styles.paymentTermSec}>
                            <Text style={styles.NoteHead}>Payment Term</Text>
                            <Text style={styles.notes}>{ProjectPDF.paymentTerm}</Text>
                        </View>
                    </View>

                    <View style={styles.section}>
                            <Text style={styles.text}>We trust that the above quotation meets with your requirements and look forward to your favorable reply. </Text>
                    </View>

                    <View style={styles.sectionNote}>
                        <View style={styles.YS}>
                            <Text style={styles.YSote}>Yours Sincerely,</Text>
                            <Text style={styles.notes}>Ferco Shutters & Seating Systems (ME) Manufacture LLC</Text>
                        </View>
                    </View>

                    <View style={styles.sectionNote}>
                        <View style={styles.YS}>
                            <Text style={styles.YSote}>Kasi Viswanath | Export Director</Text>
                            <Text style={styles.notes}>(mob) +971 554572182/+971 501070652 </Text>
                        </View>
                    </View>

                    <View style={styles.hr}></View>
                    <View style={{...styles.section, ...styles.footer}}>
                    <Image src={fercoFooterImage} style={styles.image} />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default ProjectPDFGenerator;
