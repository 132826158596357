import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import '../css/dtTable.cs'

const DtTable = ({records,columns , title, length, AddNew, loadMore} ) => {
   
    const [totalRows, setTotalRows] = useState(0);   
    const [Data, setData] = useState(records);

      const CustomTitle = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>{title}</div>
            <button type='button' className={AddNew === 'none' ? "hidden bg-blue-500 hover:bg-blue-700 text-white px-4" : "bg-blue-500 hover:bg-blue-700 text-white px-4"} onClick={AddNew}>Add New</button>
        </div>
    );

    useEffect(()=>{
        if(length > 0)
            setTotalRows(length)        
    },[length])

    useEffect(()=>{
        setData(records)
    },[records])

    const LoadData = async (page) =>{
      try {
        // Fetch data asynchronously
       await loadMore(page);
        // Update state with new data
      //  setData(newData);
      } catch (error) {
        console.error('Error loading data:', error);
      }
     // console.log(page)
    }


      
  return (    
    <DataTable
       title={<CustomTitle />}
        columns={columns}
        data={Data}
        paginationRowsPerPageOptions={[5, 10, 20, 30]} 
        pagination={true}       
        fixedHeader={true}      
        paginationServer={true}       
       
        //selectableRows={true}
        paginationTotalRows={totalRows}
       // onChangePage={LoadData}
      />
  );
};

export default DtTable;
