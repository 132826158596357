// Footer.js
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: '#F0F0F0',
    padding: 10,
    fontSize: 12,
  },
});

const DocFooter = () => (
  <View style={styles.footer}>
    <Text>Page </Text>
    <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
  </View>
);

export default DocFooter;
