import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { json, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfToday } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDownIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//Slice
import { fetchCustomers } from '../../../redux/Slice/customerSlice';
import { fetchEmployees } from '../../../redux/Slice/employeeSlice';
import { fetchProductTypes } from '../../../redux/Slice/productType';
import { addQuotation } from '../../../redux/Slice/quotationSlice';
import { fetchQuotationVm } from '../../../redux/Slice/quotationVMSlice'
import { LoadProjectsByRef } from '../../../redux/Slice/projectSlice'

//Invoice
import {  GetRefNo, GetbyId, UpdateInvoice } from '../../../redux/Slice/invoiceSlice'


const InvoiceEdit = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();   
    const [invoiceId, setInvoice] = useState(params.invoiceId);  
    const [Customers, setCutomer] = useState([]);
    const { employee } = useSelector((state) => state.employee);
    const [selectedDate, setSelectedDate] = useState(startOfToday());
    const [employees, setemployees] = useState([]);   
    const { quotationVm } = useSelector((state) => state.quotationVm);
    const { data } = useSelector((state) => state.Customer);
    const { productType } = useSelector((state) => state.productType.productType);
    const [validateDiscount, setvalidateDiscount] = useState('')
    const [grandTotal, setGrandTotal] = useState(0);
    const [showDiscount, setShowDiscount] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [SubTotal, setSubTotal] = useState(0.00);
    const [VATTotal, setVATTotal] = useState(0.00);

    const { invoice, loading, error } = useSelector((state) => state.invoice);

    console.log(invoice);


    useEffect(() => {
        setCutomer(data);
    }, [data,])
    useEffect(() => {
        setemployees(employee);
    }, [employee,])

    useEffect(() => {

        const GetbyinvoiceId = async () => {
            await dispatch(GetbyId(invoiceId));
        }
        if (parseInt(invoiceId) > 0) {
            GetbyinvoiceId();
        }

        dispatch(fetchCustomers());
        dispatch(fetchEmployees());
        dispatch(fetchProductTypes());

    }, [dispatch, invoiceId])


    const [customerInfo, setCustomerInfo] = useState({});
    const CustomerChnage = (e) => {
        const newValue = e.target.value;
        const custObj = Customers.find(x => x.cusId === parseInt(newValue));

        setCustomerInfo(custObj);
        handleChange(e);
    }

    const [employeeInfo, setemployeeInfo] = useState({});
    const EmloyeeChnage = (e) => {
        const newValue = e.target.value;
        const custObj = employee.find(x => x.employeeID === parseInt(newValue));

        setemployeeInfo(custObj);
        handleChange(e);
    }

    const handleChangeTBody = (masterId, rowId, key, value) => {
        const vatRate = 0.05;
        setMasters(prevMasters => {
            return prevMasters.map(master => {
                if (master.masterId === masterId) {
                    const updatedRows = master.rows.map(row => {
                        if (row.id === rowId) {
                            const updatedRow = { ...row, [key]: value };
                            if (key === 'unitCast' || key === 'qty') {
                                updatedRow.totalCast = (updatedRow.unitCast * updatedRow.qty).toFixed(2);
                                updatedRow.vat = (updatedRow.totalCast * vatRate).toFixed(2);
                            }                            
                            return updatedRow;
                        }
                        return row;
                    });
                    return { ...master, rows: updatedRows };
                }
                return master;
            });
        });
    };
  

    const calculateGrandTotal = () => {
        let subtotal = 0;
        let vatTotal = 0;

        if (masters && masters.length > 0) {      
            masters.forEach(master => {
                master.rows.forEach(row => {

                    const rowTotal = row.unitCast * row.qty;
                    subtotal += rowTotal;
                    if (row.vat) {
                        vatTotal += parseFloat(row.vat);
                    }
                });
            });

            let grandTotal = subtotal + vatTotal;

            if (showDiscount && discount > 0 && grandTotal > 0) {
                grandTotal -= discount;
            }

            setSubTotal(subtotal.toFixed(2));
            setVATTotal(vatTotal.toFixed(2));
            setGrandTotal(grandTotal.toFixed(2));
        }
    };
   
    const handleDiscountChange = (e) => {
        const newValue = e.target.value;       
        const totalWithoutDiscount = parseFloat(grandTotal);      
        const newValueFloat = parseFloat(newValue);
        if (newValueFloat > totalWithoutDiscount || newValueFloat === totalWithoutDiscount) {
            setvalidateDiscount("Discount can't be greater than Grand Total");
            const truncatedValue = newValue.slice(0, -1);
            setDiscount(truncatedValue);
        } else {
            setvalidateDiscount('');
            setDiscount(newValue);
        }
    };

    useEffect(() => {
        calculateGrandTotal();
        handleChange({ target: { name: 'discount', value: discount } });
    }, [discount])

    useEffect(() => {
        if (!showDiscount) {
            setDiscount(0)
        }
        handleChange({ target: { name: 'showDiscount', value: showDiscount } });    
    }, [showDiscount])

    const [hoveredRowId, setHoveredRowId] = useState(null);
    
    const handleMouseEnter = (id) => {
        setHoveredRowId(id);
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const [headings, setHeadings] = useState([{ id: 1,type:'notes', notes: '' }]);

    const addNewHeading = () => {
        let newRowId;
        if (headings.length > 0) {
            newRowId = headings[headings.length - 1].id + 1;
        } else {
            newRowId = 1;
        }

        setHeadings(prevHeadings => [
            ...prevHeadings,
            { id: newRowId, type:'notes', notes: '' }
        ]);
    };

 
    const handleNotesChange = (id, value) => {
        setHeadings(prevHeadings => {
          const newHeadings = prevHeadings.map(heading => {
            if (heading.id === id) {
              return { ...heading, notes: value };
            }
            return heading;
          });
          return newHeadings;
        });
      };


    const removeHeading = (id) => {
        setHeadings(prevHeadings => prevHeadings.filter(heading => heading.id !== id));
        setFiles(prevFiles => prevFiles.filter(file => file.headingId !== id));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    
    const handleHeadingTypeChange = (id, type) => {
        setHeadings(prevHeadings => {
            return prevHeadings.map(heading => {
                if (heading.id === id) {
                    return { ...heading, type };
                }
                return heading;
            });
        });
    };

    const [files, setFiles] = useState([]);
    const handleFileUpload = (headingId, file) => {
        if (file) {
            const newFile = {
                id: uuidv4(),
                headingId: headingId,
                name: file.name,
                file: file
            };
            setFiles(prevFiles => [...prevFiles, newFile]);
        }
    };

    const removeFile = (fileId) => {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
    };


    const [masters, setMasters] = useState([
        {
            masterId: 1, productTypeId: 1, rows: [
                { id: parseInt(Date.now()), shutterNo: '', description: '', width: '', hight: '',unitCast: '', qty: 1, totalCast: '', vat:0, claimPercentage: '' }
            ]
        }
    ]);


    // useEffect(() => {
    //     calculateGrandTotal();
    // }, [masters.rows]);

    const addProductType = () => {
        const productTypeWithRow = {
            masterId: masters.length + 1,
            productTypeId: 1,
            rows: [
                {
                    id: parseInt(Date.now()),                    
                    shutterNo: '',
                    description: '',
                    width: '',
                    hight: '',
                    unitCast: 0,
                    qty: 1,
                    totalCast: '',
                    vat : 0,
                    claimPercentage : ''
                }
            ]
        };
        setMasters([...masters, productTypeWithRow]); 
    };

    const addNewMasterRow = (masterId) => {
        const newDetail = { id: parseInt(Date.now()), shutterNo: '', description: '', width: '', hight: '', unitCast: '', Qqty: 1, totalCast: '', vat:0, claimPercentage: '' }
        const updatedMasters = masters.map(master =>
            master.masterId === masterId ? { ...master, rows: [...master.rows, newDetail] } : master
        );       
        setMasters(updatedMasters);
    };

    const removeRow = (masterId, rowId) => {        
        const updatedMasters = masters.map(master =>
            master.masterId === masterId ? {
                ...master,
                rows: master.rows.filter(row => row.id !== rowId)
            } : master
        );
        setMasters(updatedMasters);  
        setHoveredRowId(null);
    };


    useEffect(()=>{
        calculateGrandTotal();
        handleChange({ target: { name: 'masters', value: masters } });
    },[masters])
    
    const removeProductType = (masterId) => {       
        const updatedMasters = masters.filter(master => master.masterId !== masterId);
        setMasters(updatedMasters);
    };

    const [productTypes, setProductTypes] = useState([]);
    useEffect(()=>{
        setProductTypes(productType);
    },[productType])
    
    useEffect(()=>{
        handleChange({ target: { name: 'headings', value: headings } });
    },[headings])
    
    const onProductTypeChange = (e, masterId) => {
        const newValue =parseInt(e.target.value);
        const updatedMasters = masters.map(master => {
            if (master.masterId === masterId) {
                return { ...master, productTypeId: newValue };
            }
            return master;
        });
        setMasters(updatedMasters);
    }
  
    useEffect(() => {        
        setMasters((invoice.InvoiceObj.masters));
        
        // Update formData with invoiceId
        setformData(prev => ({
            ...prev,
            invoiceId: invoice.InvoiceObj.invoiceId,
            invoiceRefNo: invoice.InvoiceObj.invoiceRefNo,
            ProjectRefNo: invoice.InvoiceObj.projectRefNo,
            InvoiceDate: invoice.InvoiceObj.invoiceDate,
            CustomerId: invoice.InvoiceObj.customerId,
            SalesPerson: invoice.InvoiceObj.salesPerson,
            DueDate : invoice.InvoiceObj.dueDate,
            grandTotal: parseFloat(invoice.InvoiceObj.grandTotal),
            subtotal : parseFloat(invoice.InvoiceObj.subTotal),
            vatAmount: parseFloat(invoice.InvoiceObj.vatAmount),
            discount : parseFloat(invoice.InvoiceObj.discount),
            ProjectId: invoice.InvoiceObj.ProjectId,
            PaymentTerm: invoice.InvoiceObj.paymentTerm,
            BankDetailId : invoice.InvoiceObj.bankDetailId,
            masters : invoice.InvoiceObj.masters,
            headings: invoice.InvoiceObj.headings
        }));       
        
        EmloyeeChnage({ target: { name: 'e', value: invoice.InvoiceObj.salesPerson } });
        CustomerChnage({ target: { name: 'e', value: invoice.InvoiceObj.customerId } });
        setDiscount(parseFloat(invoice.InvoiceObj.totalDisCount).toFixed(2));
        setSubTotal(parseFloat(invoice.InvoiceObj.subTotal).toFixed(2));
        setVATTotal(parseFloat(invoice.InvoiceObj.vatAmount).toFixed(2));
        setGrandTotal(parseFloat(invoice.InvoiceObj.grandTotal).toFixed(2));
        setHeadings(invoice.InvoiceObj.headings);

    }, [invoice.InvoiceObj]);


    const [formData, setformData] = useState({
        invoiceId: 0,
        invoiceRefNo : '',
        CustomerId: 0,       
        SalesPerson: 0,        
        ProjectRefNo: '',
        InvoiceDate: selectedDate,
        DueDate: startOfToday(),
        masters: masters,
        headings: headings,
        files: files,
        showDiscount: 0,
        totalDisCount: 0, 
        grandTotal: 0,
        SubTotal: 0,
        VATAmount: 0,
        PaymentTerm: '',
        BankDetailId: 1,
        CreatedBy : 1,
        ProjectId: 0,

    })

    const handleChange = (event) => {       
        const { name, value } = event.target;
        setformData((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    
    useEffect(()=>{
        handleChange({ target: { name: 'grandTotal', value: grandTotal } });
        handleChange({ target: { name: 'totalDisCount', value: discount } });
        handleChange({ target: { name: 'VATAmount', value: VATTotal } });
        handleChange({ target: { name: 'SubTotal', value: SubTotal } });
    },[grandTotal, discount])


    useEffect(()=>{
        handleChange({ target: { name: 'files', value: files } });
    },[files])

    const [SaveText, setSaveText] = useState("Save");

    const saveInvoice = async () => {

        if(formData.CustomerId === 0)
        {
            alert("Please select customer")
        }
        else if(formData.SalesPerson === 0)
        {
            alert("Please select Sales Person")
        }
        else
        {
            setSaveText("creating wait...")
            const Data = JSON.stringify(formData);
            const form = new FormData();
            if(files.length > 0)
            {
                for (let i = 0; i < files.length; i++) {
                    form.append('Files', files[i].file);
                }     
            }            
            form.append('Input', Data);
         
            const response = await dispatch(UpdateInvoice(form));
            if (parseInt(response.payload) > 0) 
            {
                navigate("/Invoice")
                //navigate(`/QuotationPrint/${parseInt(response.payload)}`)
            }
            else
            {
                setSaveText("Save")
                alert("Something went wrong");
            }
        }
    }

    const [file, setFile] = useState(null);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };
      const handleAdd = async () => {
        if (file) {
          const form = new FormData();
          form.append('file', file);
          form.append('QuotationData', JSON.stringify(formData));
      
          try {
            const response = await axios.post('https://localhost:7034/api/quotation/Upload', form, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            console.log(response.data);            
          } catch (error) {
            console.error('Error uploading file:', error);            
          }
        }
      };

      const [filteredSuggestions, setFilteredSuggestions] = useState([]);
      const { project } = useSelector((state) => state.project);

        useEffect(()=>{
            setFilteredSuggestions(project.projectRefs);
        },[project.projectRefs])

      const handleProjectRefChange = async (e) =>
      {
            const value = e.target.value;
            handleChange({ target: { name: 'ProjectRefNo', value: value } });           
            // if(value.length > 2)
            // {
            //     //dispatch(LoadProjectsByRef(value));
            //     handleChange({ target: { name: 'ProjectRefNo', value: value } });
            // }
            // else
            // {
            //     handleChange({ target: { name: 'ProjectRefNo', value: value } });
            // }
      }
    
      const handleSuggestionClick = (suggestion) => {      
        handleChange({ target: { name: 'ProjectRefNo', value: suggestion } });
        setFilteredSuggestions([]);
        //onSuggestionSelected(suggestion);
      };

    if (loading === 'loading') {
        return <p>Loading...</p>;
    }

    if (loading === 'failed') {
        return <p>Error: {error}</p>;
    }

    const Cancel = ()=> {
        navigate("/Invoice")
    }
  

  return (
         <div className='w-full'>
             <section class="bg-white dark:bg-gray-900">
                 <div className='flex justify-between items-center bg-gray-100'>
                     <div className='text-right ml-4'>
                         Invoice
                     </div>
                     <button className='inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1 mb-1 mr-1'
                         onClick={() => Cancel()}
                     >
                         Cacel
                     </button>
                 </div>
                 <div class="flex items-center justify-center bg-white">
                    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-96 lg:w-full lg:grid lg:grid-cols-6 lg:gap-4">
                        <div class="lg:col-span-2 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="Customer">
                                Customer
                            </label>
                            <select class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="CustomerId"
                                name="CustomerId"
                                value={formData.CustomerId}
                                onChange={(e) => CustomerChnage(e)}
                            >
                                {Customers && Customers.map(item => (
                                    <option key={item.cusId} value={item.cusId}>{item.cusName}</option>
                                ))}
                            </select>
                        </div>
                        <div class="mb-2 lg:col-span-2">
                            <input type="file" className='hidden' onChange={handleFileChange} />
                            <button type='button' className='hidden' onClick={handleAdd}>Upload</button>
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                            Invoice Date:
                            </label>
                            <DatePicker
                                className="appearance-none bg-gray-50 border focus:outline-none focus:shadow-outline leading-tight py-2 react-datepicker-ignore-onclickoutside rounded text-gray-700 w-96 text-center"
                                selected={selectedDate}
                                onChange={handleDateChange}
                                isClearable
                                dateFormat="EEEE, dd-MMMM-yyyy"
                                showYearDropdown
                                scrollableYearDropdown
                            />
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Address:
                            </label>
                            <label class="h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{customerInfo && customerInfo.cusAddress}</label>
                        </div>
                        <div class="lg:col-span-2"></div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-1 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Invoice No #:
                            </label>
                            <label class="h-8 appearance-none border rounded w-full py-2 px-3 ml-1 text-gray-700 leading-tight font-bold focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{formData.invoiceRefNo}</label>
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-1 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Attention To:
                            </label>
                            <label class="h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{customerInfo && customerInfo.cusAttentionTo}</label>
                        </div>
                        <div class="lg:col-span-2"></div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Project No #:
                            </label>
                            <input class="h-8 appearance-none border rounded py-2 px-3 font-bold ml-2 text-gray-700 leading-tight w-full focus:outline-none focus:shadow-outline text-[0.8rem]"
                                id="ProjectRefNo"
                                name="ProjectRefNo"
                               value={formData.ProjectRefNo}
                               onChange={handleProjectRefChange}
                               >
                            </input>
                          {/* <ul>
                              {filteredSuggestions && filteredSuggestions.map((suggestion, index) => (
                                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                      {suggestion.value}
                                  </li>
                              ))}
                          </ul> */}
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                E-Mail:
                            </label>
                            <label class="h-8 appearance-none border rounded w-full py-2 px-3 ml-2 text-blue-600 leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{customerInfo && customerInfo.cusContactEmail}</label>
                        </div>
                        <div class="lg:col-span-2"></div>
                        <div class="mb-2 lg:col-span-2 flex items-center whitespace-nowrap">
                            <label class="block text-gray-700 text-sm mb-2 mr-8" htmlFor="SalesPerson">
                                Sales
                            </label>
                            <select class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="SalesPerson"
                                name="SalesPerson"
                                value={formData.SalesPerson}
                                onChange={(e) => EmloyeeChnage(e)}
                            >
                                {employees && employees.map(item => (
                                    <option key={item.employeeID} value={item.employeeID}>{`${item.firstName} ${item.lastName}`}</option>
                                ))}
                            </select>
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center  whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Phone:
                            </label>
                            <label class="h-8 appearance-none border rounded w-full py-2 px-3 ml-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{customerInfo && customerInfo.cusContactNumer}</label>
                        </div>
                        <div className='lg:col-span-2'></div>
                        <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap">
                            <label class="h-8 !bg-transparent block font-bold font-normal mr-6 text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Email:
                            </label>
                            <label class="h-8 appearance-none border rounded py-2 px-3 ml-2 text-blue-600 leading-tight w-full focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">
                                {employeeInfo && employeeInfo.email}
                            </label>
                        </div>
                        <div class="lg:col-span-2 text-left flex items-center  whitespace-nowrap">
                            <label class="!bg-transparent block font-bold font-normal text-gray-700 text-[0.8rem]" htmlFor="Customer">
                                Customer Type:
                            </label>
                            <label class="h-8 ml-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{customerInfo && customerInfo.cusContactNumer}</label>
                        </div>
                        <div className='lg:col-span-6'>
                            <div className="w-full">

                                <table className="w-full" style={{ borderCollapse: 'collapse' }}>
                                    <thead className="text-[0.8rem]">
                                        <tr className="bg-gray-200">
                                            <th className="border px-4 py-2">S.No</th>
                                            <th className="border px-4 py-2">Shutter No</th>
                                            <th className="border px-4 py-2">Description</th>
                                            <th className="border px-4 py-2">Width</th>
                                            <th className="border px-4 py-2">Height</th>
                                            <th className="border px-4 py-2">Unit Cost (AED)</th>
                                            <th className="border px-4 py-2">QTY</th>
                                            <th className="border px-4 py-2">Total (AED)</th>
                                            <th className="border px-4 py-2">VAT (AED)</th>
                                            <th className="border px-4 py-2">Claim %</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-[0.8rem]">
                                        {masters && masters.map(master => (
                                            <React.Fragment key={master.masterId}>
                                                <tr class="bg-gray-300">                                                   
                                                    <td className="border px-4 py-2 text-left " colSpan={7}>
                                                        <select className='w-full font-medium bg-gray-300'
                                                         onChange={(e) => onProductTypeChange(e, master.masterId)}
                                                         value={master.productTypeId}
                                                        >   
                                                            {productTypes.length > 0 && productTypes.map((prodcutType) => (
                                                                <option key={prodcutType.id} value={prodcutType.id}>{prodcutType.title}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td class="bg-gray-700 text-white cursor-pointer" colSpan={2} onClick={() => removeProductType(master.masterId)} title='Delete this product type' >Delete Product</td>
                                                    <td class="bg-gray-100 flex justify-between cursor-pointer" onClick={() => addNewMasterRow(master.masterId)}>
                                                        <button type='button' className='mr-2 ml-2'>Add row </button><span><ArrowDownIcon className="h-8 text-white text-gray-500" /> </span> 
                                                    </td>
                                                </tr>
                                                {master.rows.map((row, index) => (
                                                    <tr key={row.id}>
                                                        <td className="border px-4 py-2 w-4"
                                                            onMouseEnter={() => handleMouseEnter(row.id)}
                                                            onMouseLeave={handleMouseLeave}>
                                                            <label className={!hoveredRowId || hoveredRowId !== row.id ? 'block' : 'hidden'}>{index + 1}</label>
                                                            {hoveredRowId === row.id && (
                                                                <button className='text-red-500' title='Remove this row' onClick={() => removeRow(master.masterId, row.id)}><TrashIcon className="h-5 w-5 text-red-500" /></button>
                                                            )}
                                                        </td>
                                                        <td className="border px-4 py-2 w-12"><input type="text" value={row.shutterNo} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'shutterNo', e.target.value)} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-60"><textarea value={row.description} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'description', e.target.value)} className="border px-2 py-1 w-full h-12" rows="2"></textarea></td>
                                                        <td className="border px-4 py-2 w-12"><input type="number" value={row.width} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'width', e.target.value)} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-12"><input type="number" value={row.hight} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'hight', e.target.value)} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-24"><input type="number" value={row.unitCast} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'unitCast', e.target.value)} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-12"><input type="number" value={row.qty} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'qty', e.target.value)} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-24"><input type="number" value={row.totalCast} readOnly={true} className="border px-2 py-1 w-full" /></td>
                                                        <td className="border px-4 py-2 w-24"><input type="number" value={row.vat} readOnly={true} className="border px-2 py-1 w-full" title='vat, readOly' /></td>
                                                        <td className="border px-4 py-2 w-24"><textarea  value={row.claimPercentage} onChange={(e) => handleChangeTBody(master.masterId, row.id, 'claimPercentage', e.target.value)} title='Claim Percentage'  className="border px-2 py-1 w-full" ></textarea></td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        {showDiscount && (
                                            <tr className='bg-gray-200'>
                                                <td className="border px-4 py-2 w-24 text-right" colSpan="9">Discount</td>
                                                <td className="border px-4 py-2 w-24">
                                                    <input
                                                        type="number"
                                                        value={discount}
                                                        onChange={handleDiscountChange}
                                                        className="border px-2 py-1 w-full"
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        <tr class="bg-gray-700 font-bold text-white">
                                            <td className="border text-[0.8rem] cursor-pointer" colSpan={2}><button type='button' title='Add new Product Type' onClick={() => addProductType()} >Add Prod</button> | <button type='button' title={showDiscount ? 'Removed Discount' : 'Add discount'} className="text-blue-500" onClick={() => setShowDiscount(!showDiscount)} >{showDiscount ? "Rem Dis" : "Add Dis"}</button></td>
                                            <td className="border px-4 py-2 text-right" colSpan={7}>Sub Total</td>
                                            <td className="border px-4 py-2">{SubTotal}</td>
                                        </tr>
                                        <tr class="bg-gray-700 font-bold text-white">
                                         <td className="border px-4 py-2 text-right" colSpan={9}>VAT Total</td>
                                            <td className="border px-4 py-2">{VATTotal}</td>
                                        </tr>
                                        <tr class="bg-gray-700 font-bold text-white">
                                            <td className="border px-4 py-2 text-right" colSpan={9}>GrandTotal Total</td>
                                            <td className="border px-4 py-2">{grandTotal}</td>
                                        </tr>
                                    </tfoot>
                                </table>

                                <div className="mt-4 rounded">
                                    {headings && headings.map(heading => (
                                        <div key={heading.id}>
                                            <div className="flex justify-between bg-gray-200">
                                                <select
                                                    className="text-lg mt-1 font-semibold mb-2 ml-2 text-gray-700 text-left bg-gray-200"
                                                    value={heading.type}
                                                    onChange={(e) => handleHeadingTypeChange(heading.id, e.target.value)}
                                                >
                                                    <option value="Notes">Notes</option>
                                                    <option value="Inclusion">Inclusion</option>
                                                    <option value="Exclusion">Exclusion</option>
                                                    <option value="Attachment">Attachment</option>
                                                </select>
                                                <button
                                                    type='button'
                                                    className="mb-2 mt-2 mr-2 bg-red-500 hover:bg-red-700 text-white px-4 rounded"
                                                    onClick={() => removeHeading(heading.id)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                            {heading.type !== 'Attachment' ? (
                                                <textarea
                                                    className="border p-2 w-full h-24 text-gray-700"
                                                    placeholder={`Add points for Heading ${heading.id}...`}
                                                    value={heading.notes || ''}
                                                    onChange={(e) => handleNotesChange(heading.id, e.target.value)}
                                                ></textarea>
                                            ) : (
                                                <>
                                                    <div className='Files'>
                                                        {files.map((file, fileIndex) => file.headingId === heading.id && (
                                                            <div key={file.id} className="flex items-center mt-2">
                                                                <span className="text-gray">{file.file.name} {fileIndex}</span>
                                                                <button
                                                                    type="button"
                                                                    className="ml-2 text-red-500"
                                                                    onClick={() => removeFile(file.id)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <hr />
                                                    <input
                                                        type="file"
                                                        className="border p-2 w-full text-gray-700"
                                                        onChange={(e) => handleFileUpload(heading.id, e.target.files[0])}
                                                    />
                                                    {/* Display uploaded file with remove option */}

                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <div className='w-full text-left bg-blue-500'>
                                        <button
                                            type='button'
                                            className="bg-black/50 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                            onClick={addNewHeading}
                                        >
                                            Add New Heading
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-4 rounded text-left">
                                    <div class="mb-2 lg:col-span-2 flex items-center whitespace-nowrap">
                                        <label class="block text-gray-700 text-sm font-bold mb-2 mr-2" htmlFor="payementTerm">
                                            Payment Term
                                        </label>
                                        <input class="shadow w-full appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="PaymentTerm"
                                            name="PaymentTerm"
                                            value={formData.PaymentTerm}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between">
                                    {/* <button type='button' className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => addNewRow()}>Add New Row</button> */}
                                    <label className='text-red-500'>{validateDiscount}</label>
                                    <button type='button' className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" disabled={SaveText === "Save" ?  false : true}  onClick={() => saveInvoice()}>{SaveText}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
             </section>
         </div>
     )
}

export default  InvoiceEdit;