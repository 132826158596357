// QuotationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';

export const fetchQuotations = createAsyncThunk('quotation/fetchQuotations', async () => {  
  const response = await axios.post(`${API_BASE_URL}quotation/All`,{"page": 1,"pageSize": 1000}); 
  return response.data;
});

export const addQuotation = createAsyncThunk('quotation/addQuotation', async (newQuotation) => {  
  const response = await axios.post(`${API_BASE_URL}quotation/Add`, newQuotation, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }})
  return response.data;
});

export const UpdateQuotation = createAsyncThunk('quotation/UpdateQuotation', async (formDatas) => {  
  const response = await axios.post(`${API_BASE_URL}quotation/Update`, formDatas, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }});
  return response.data;
});

export const CreateRevision = createAsyncThunk('quotation/CreateRevision', async (QuotId) => { 
  const response = await axios.post(`${API_BASE_URL}Quotation/CreateRevision?QuotId=${QuotId}`, {});
  return response.data;
});

export const DeleteQuotation = createAsyncThunk('quotation/DeleteQuotation', async (QuotId) => { 
  const response = await axios.post(`${API_BASE_URL}Quotation/Delete?Id=${QuotId}`, {});
  return response.data;
});

export const DeleteDetailsRow = createAsyncThunk('quotation/DeleteDetailsRow', async (DetailId) => { 
  const response = await axios.post(`${API_BASE_URL}QuotDataDetails/Delete?DetailId=${DetailId}`, {});
  return response.data;
});

export const DeleteMasterRow = createAsyncThunk('quotation/DeleteMasterRow', async (DetailsIds) => { 
  const response = await axios.post(`${API_BASE_URL}QuotationDataMaster/Delete`, DetailsIds, {});
  return response.data;
});

export const UpdateCustomerId = createAsyncThunk('quotation/UpdateCustomerId', async (cData) => { 
  const response = await axios.post(`${API_BASE_URL}Quotation/UpdateCustomerId?QuotId=${cData.QuotId}&CustomerId=${cData.CustomerId}`, {});
  return response.data;
});

export const UpdateSalesPersonId = createAsyncThunk('quotation/UpdateSalesPersonId', async (sData) => { 
  const response = await axios.post(`${API_BASE_URL}Quotation/UpdateSalesPerson?QuotId=${sData.QuotId}&SalesPerson=${sData.SalesPersonId}`, {});
  return response.data;
});

export const DeleteHeadinRow = createAsyncThunk('quotation/DeleteHeadinRow', async (Heading) => { 
  const response = await axios.post(`${API_BASE_URL}QuotationHeading/DeleteHeadinRow?QuotId=${Heading.QuotId}&HeadingId=${Heading.HeadingId}`, {});
  return response.data;
});

export const RemoveAttachment = createAsyncThunk('quotation/RemoveAttachment', async (Heading) => { 
  const response = await axios.post(`${API_BASE_URL}Quotation/RemoveAttachment?AttachmentId=${Heading.AttachmentId}`, {});
  return response.data;
});



const quotationSlice = createSlice({
  name: 'quotation',
  initialState:  { quotation: [], loading: 'idle', error: null, dataCount : 0 },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotations.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchQuotations.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        const { quotations, totalCount } = action.payload;
        state.quotation = quotations;
        state.dataCount = totalCount
      })
      .addCase(fetchQuotations.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addQuotation.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(addQuotation.fulfilled, (state, action) =>{
        state.loading = "succeeded"
        state.quotation = action.payload;     
      })
      .addCase(addQuotation.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      })
      .addCase(UpdateQuotation.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(UpdateQuotation.fulfilled, (state, action) =>{
        state.loading = "succeeded"
        state.quotation = action.payload;     
      })
      .addCase(UpdateQuotation.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      })
      .addCase(CreateRevision.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(CreateRevision.fulfilled, (state, action) =>{
        state.loading = "succeeded"        
      })
      .addCase(CreateRevision.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(DeleteQuotation.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(DeleteQuotation.fulfilled, (state, action) =>{
        state.loading = "succeeded"     
        state.quotation = action.payload;        
      })
      .addCase(DeleteQuotation.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(DeleteDetailsRow.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(DeleteDetailsRow.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(DeleteDetailsRow.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(DeleteMasterRow.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(DeleteMasterRow.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(DeleteMasterRow.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(UpdateCustomerId.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(UpdateCustomerId.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(UpdateCustomerId.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(UpdateSalesPersonId.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(UpdateSalesPersonId.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(UpdateSalesPersonId.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(DeleteHeadinRow.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(DeleteHeadinRow.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(DeleteHeadinRow.rejected, (state, action) => {
        state.loading = "failed"       
      })
      .addCase(RemoveAttachment.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(RemoveAttachment.fulfilled, (state, action) =>{
        state.loading = "succeeded"  
      })
      .addCase(RemoveAttachment.rejected, (state, action) => {
        state.loading = "failed"       
      });

  },
});

export default quotationSlice.reducer;
