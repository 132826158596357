import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { fetchDesignation } from '../../../redux/Slice/designationSlice';

 function Designation() {

    const dispatch = useDispatch();

    const { designation } = useSelector((state) => state.designation); 

    const loadDesignation = async () => {
        const response = await dispatch(fetchDesignation());
    } 

    useEffect(() => {
        loadDesignation();
    },[])

    console.log(designation);

    return (
        <div className="w-full">
            <section className="bg-white dark:bg-gray-900">
                <div className='flex justify-between items-center bg-gray-100'>
                    <div className='text-right ml-4'>
                        Position Information
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">  
                            <tr>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                   Position Name
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    Created By
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    IsActive
                                </th>                               
                            </tr>
                        </thead>
                        <tbody>
                            {designation.length > 0 && (
                                designation.map((des, Index) => (
                                    <tr key={Index} class="border">
                                        <td scope="col" class="px-4 sm:px-6 py-3 whitespace-nowrap">
                                            {des.desName}
                                        </td>
                                        <td scope="col" class="px-4 sm:px-6 py-3 whitespace-nowrap">
                                        Test User                                   
                                        </td>
                                        <td class="px-3 sm:px-6 py-4">
                                            <input
                                                type="checkbox"                                               
                                                checked={des.isActive}
                                               // onChange={() => handleOnLeaveChange(!ShifAtten.onLeave, Index)}
                                                disabled={false}
                                            />
                                        </td>                                        
                                    </tr>
                                ))
                            )}
                        </tbody>                       
                    </table>                   
                </div>
            </section>
        </div>
    )
}


export default Designation;