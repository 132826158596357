// ProjectSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchProjects = createAsyncThunk('project/fetchProjects', async (paginationModel) => {
    const response = await axios.post(`${API_BASE_URL}Project/All`,{"page": paginationModel.page,"pageSize": paginationModel.pageSize});
    return response.data;
  });
  

  export const CreateProject = createAsyncThunk('project/CreateProject', async (pojectMasterVM) => {
    const response = await axios.post(`${API_BASE_URL}Project/Create`,pojectMasterVM);
    return response.data;
  });


  export const GetProjectRef = createAsyncThunk('project/GetProjectRef', async () => {
    const response = await axios.post(`${API_BASE_URL}Project/GetProjectRef`,{});
    return response.data;
  });


  export const LoadProjectsByRef = createAsyncThunk('project/LoadProjectsByRef', async (projectRefNo) => {
    const response = await axios.post(`${API_BASE_URL}Project/LoadProjectsByRef?projectRefNo=`+projectRefNo,{
        headers: {
            'Content-Type': 'application/json'
          }
    });
    return response.data;
  })



const projectSlice = createSlice({
    name: 'project',
    initialState: { project: {
        project: [],
        Projectid: 0,
        ProjectRef : '',   
        loading: 'idle', error: null,projectCount: 0,
        projectRefs: [] 
    }},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.project.loading = 'loading';
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {               
                state.project.loading = 'succeeded';
                const { projects, totalCount } =  action.payload;
                state.project.project = projects;
                state.project.projectCount = totalCount;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.project.loading = 'failed';
                state.project.error = action.error.message;

            })
            .addCase(CreateProject.pending, (state) => {
                state.project.loading = 'loading';
            })
            .addCase(CreateProject.fulfilled, (state, action) => {
                state.project.loading = 'succeeded';
                state.project.Projectid = action.payload;
            })
            .addCase(CreateProject.rejected, (state, action) => {
                state.project.loading = 'failed';
                state.project.error = action.error.message;
            })
            .addCase(GetProjectRef.pending, (state) => {
                state.project.loading = 'loading';
            })
            .addCase(GetProjectRef.fulfilled, (state, action) => {
                state.project.loading = 'succeeded';
                state.project.ProjectRef = action.payload;
            })
            .addCase(GetProjectRef.rejected, (state, action) => {
                state.project.loading = 'failed';
                state.project.error = action.error.message;
            })
            .addCase(LoadProjectsByRef.pending, (state) => {
                state.project.loading = 'loading';
            })
            .addCase(LoadProjectsByRef.fulfilled, (state, action) => {
                state.project.loading = 'succeeded';
                state.project.projectRefs = action.payload;
            })
            .addCase(LoadProjectsByRef.rejected, (state, action) => {
                state.project.loading = 'failed';
                state.project.error = action.error.message;
            });
    },
});

export default projectSlice.reducer;