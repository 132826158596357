import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../../apiConfig";


export const fetchDepartments = createAsyncThunk('department/fetchDepartments', async () => {
    const response = await axios.get(`${API_BASE_URL}Department/All`);
    return response.data;
  });


const departmentSlice = createSlice({
    name: 'department', 
    initialState: { department: [], loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartments.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.department = action.payload;
            })
            .addCase(fetchDepartments.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export default departmentSlice.reducer;