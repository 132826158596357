// InvoiceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchInvoices = createAsyncThunk('invoice/fetchInvoices', async () => {
    const response = await axios.get(`${API_BASE_URL}Invoice/All`);
    return response.data;
});


export const GetRefNo = createAsyncThunk('invoice/GetRefNo', async () => {
    const response = await axios.post(`${API_BASE_URL}Invoice/GetRefNo`, {});
    return response.data;
});


export const addInvoice = createAsyncThunk('invoice/addInvoice', async (newInvoice) => {  
    const response = await axios.post(`${API_BASE_URL}invoice/Create`, newInvoice, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
    return response.data;
  });

export const UpdateInvoice = createAsyncThunk('invoice/UpdateInvoice', async (newInvoice) => {  
    const response = await axios.post(`${API_BASE_URL}invoice/Update`, newInvoice, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
    return response.data;
  });


  export const GetbyId = createAsyncThunk('invoice/GetbyId', async (InvoieId) => {  
    const response = await axios.post(`${API_BASE_URL}invoice/GetbyId?InvoieId=${InvoieId}`, {
      headers: {
        'Content-Type': 'application/json'
      }})
    return response.data;
  });




  const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: { invoice: {
        Invoice: [],
        Invoiceid: 0,
        InvoiceRef : '',
        InvoiceObj: {}

    }, loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvoices.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchInvoices.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.invoice.invoice = action.payload;
            })
            .addCase(fetchInvoices.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(GetRefNo.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(GetRefNo.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.invoice.InvoiceRef = action.payload;
            })
            .addCase(GetRefNo.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(addInvoice.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(addInvoice.fulfilled, (state, action) => {
                state.loading = 'succeeded';                
            })
            .addCase(addInvoice.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(GetbyId.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(GetbyId.fulfilled, (state, action) => {
                state.loading = 'succeeded';    
                state.invoice.InvoiceObj = action.payload            
            })
            .addCase(GetbyId.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
            .addCase(UpdateInvoice.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(UpdateInvoice.fulfilled, (state, action) => {
                state.loading = 'succeeded';                      
            })
            .addCase(UpdateInvoice.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
        },
    });
    
    export default invoiceSlice.reducer;