// employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import API_BASE_URL from '../../apiConfig';

export const fetchEmployees = createAsyncThunk('employee/fetchEmployees', async () => {
  const response = await axios.get(`${API_BASE_URL}Employee/All`);
  return response.data;
});

export const addEmployee = createAsyncThunk('euser/addEmployee', async (newEmployee) => {
  const response = await axios.post(`${API_BASE_URL}Employee/Add`, newEmployee);
  return response.data;
});

const employeeSlice = createSlice({
  name: 'employee',
  initialState: { employee: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.employee = action.payload;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addEmployee.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(addEmployee.fulfilled, (state, action) =>{
        state.loading = "succeeded"
        state.employee = action.payload;     
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      });
  },
});

export default employeeSlice.reducer;
