import React, { useEffect, useState } from 'react'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

//slice
import { fetchShiftAttendanc, addShiftAttendance } from '../../../redux/Slice/shiftAttendance';
import { useNavigate } from 'react-router-dom';


const AttendanceAdd = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [comments, setComments] = useState('');
    const [shiftAttendanceAdd, setshiftAttendanceAdd] = useState([]);
    const { shiftAttendance } = useSelector((state) => state.shiftAttendance);
    const [postAendance, setPostAttendance] = useState([]);


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleInTimeChange = (inTime, index) => {
        setshiftAttendanceAdd((prevShiftAttendanceAdd) => {
            const updatedArray = [...prevShiftAttendanceAdd];
            updatedArray[index] = {
                ...updatedArray[index],
                inTime: inTime,
                isOnLeaveDisabled: inTime === '00:00:00'
            };
            // Call the function to update totalWorkedHrs for the specific index
            return calculateTotalTimeForArray(updatedArray);
        });
    };

    const handleOutTimeChange = (outTime, index) => {
        setshiftAttendanceAdd((prevShiftAttendanceAdd) => {
            const updatedArray = [...prevShiftAttendanceAdd];
            updatedArray[index] = {
                ...updatedArray[index],
                outTime: outTime
            };
            // Call the function to update totalWorkedHrs for the specific index
            return calculateTotalTimeForArray(updatedArray);
        });
    }

    const calculateTotalTimeForArray = (attendanceArray) => {
        // Calculate total time for each object in the array
        const updatedArray = attendanceArray.map((attendance) => {
            const inTime24 = new Date(`01/01/2000 ${attendance.inTime}`).toLocaleTimeString('en-US', { hour12: false });
            const outTime24 = new Date(`01/01/2000 ${attendance.outTime}`).toLocaleTimeString('en-US', { hour12: false });

            const startTime = new Date(`01/01/2000 ${inTime24}`);
            const endTime = new Date(`01/01/2000 ${outTime24}`);

            if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
                // Handle invalid time strings
                return {
                    ...attendance,
                    totalWorkedHrs: 'Invalid time format',
                };
            }

            const timeDiff = endTime - startTime;
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

            return {
                ...attendance,
                totalWorkedHrs: `${hours}:${minutes}`,
            };
        });

        return updatedArray;
    };

    const handleOnLeaveChange = (newOnLeaveValue, index) => {
        setshiftAttendanceAdd((prevShiftAttendanceAdd) => {
            const updatedArray = [...prevShiftAttendanceAdd];
            updatedArray[index] = {
                ...updatedArray[index],
                onLeave: newOnLeaveValue
            };
            return calculateTotalTimeForArray(updatedArray);
        });
    };

    const getShiftEmployeeData = async () => {
        var response = await dispatch(fetchShiftAttendanc());
    }

    useEffect(() => {
        setshiftAttendanceAdd(shiftAttendance)
    }, [shiftAttendance])

    useEffect(() => {
        getShiftEmployeeData();
    }, [])


    const handlePostAttendance = () => {

        const calculatedAttendance = shiftAttendanceAdd.map((attendance) => {

            const { overtimeSalary, totalSalary, overtimeHours, salaryPerHour, OTRate, regularSalary } = attendance.onLeave ? returnOnLeave() : calculateOTSalary(attendance.totalWorkedHrs, attendance.salary, selectedDate);
          
            return {
                attendanceDate: selectedDate,
                employeeId: attendance.employeeID,
                onLeave: attendance.onLeave,
                projectNo: 1,
                shift: attendance.shiftName,
                inTime: attendance.onLeave ? "00:00" : attendance.inTime,
                outTime:  attendance.onLeave ? "00:00" : attendance.outTime,
                totalWorkedHrs: attendance.totalWorkedHrs,
                basicSalary: attendance.salary,
                ot: attendance.onLeave ? 0.0 : overtimeHours,
                salaryPerHour: attendance.onLeave ? 0.0 : salaryPerHour,
                otRate: attendance.onLeave ? 0.0 : OTRate,
                otSalary: attendance.onLeave ? 0.0 : overtimeSalary,
                regularSalary: attendance.onLeave ? 0.0 : 0.0,
                totalSalary: attendance.onLeave ? 0.0 : totalSalary,               
                comments: attendance.comments || ''               
            };
        });
        setPostAttendance(calculatedAttendance);           
    };


    useEffect(() => {
        const addnewAttendance = async (attendances) => {
          try {
            const response = await dispatch(addShiftAttendance(attendances));
            console.log(response);       
            navigate("/EmpAttendance");   
            // Do something with the successful response if needed
          } catch (error) {
            console.error('Error adding Shift Attendance:', error);
            // Handle the error
          }
        };
    
        if (postAendance.length > 0) {

           const Newattendances = [];

            for(var i=0; i< postAendance.length; i++)
            {
              const attendance = {                
                    attendanceDate: postAendance[i].attendanceDate,
                    employeeId: postAendance[i].employeeId,
                    onLeave: postAendance[i].onLeave,
                    projectNo: postAendance[i].projectNo,
                    shift: postAendance[i].shift,
                    inTime: postAendance[i].inTime,
                    outTime: postAendance[i].outTime,
                    totalWorkedHrs: postAendance[i].totalWorkedHrs.toString(),
                    ot: postAendance[i].ot.toString(),
                    salaryPerHour: postAendance[i].salaryPerHour.toString(),
                    otRate: postAendance[i].otRate.toString(),
                    otSalary: postAendance[i].otSalary.toString(),
                    regularSalary: postAendance[i].regularSalary.toString(),
                    totalSalary: postAendance[i].totalSalary.toString(),
                    basicSalary: postAendance[i].basicSalary.toString(),
                    comments: postAendance[i].comments.toString(),                 
                };
                Newattendances.push(attendance);
            }
            if (Newattendances.length > 0) {
                addnewAttendance(Newattendances);              
            }
        }
    }, [dispatch, postAendance]);


    const calculateOTSalary = (totalWorkedHours, baseSalary, selectedDate) => {
        const totalDaysInMonth = calculateTotalDaysInMonth(selectedDate);
        const salaryPerHour = (baseSalary / 26 / 8).toFixed(2);
        const isSunday = selectedDate.getDay() === 0;
        const numericTotalWorkedHours = convertTimeStringToNumeric(totalWorkedHours);
        const regularHours = isSunday
            ? 0
            : Math.min(numericTotalWorkedHours, 8);
        const overtimeHours = isSunday
            ? totalDaysInMonth * 8
            : Math.max(0, numericTotalWorkedHours - regularHours);

        const regularSalary = (numericTotalWorkedHours * salaryPerHour).toFixed(2);
        const overtimeSalary = isSunday
            ? (overtimeHours * (salaryPerHour * 2.50)).toFixed(2)
            : (overtimeHours * (salaryPerHour * 1.25)).toFixed(2);

        const totalSalary = (parseFloat(regularSalary) + parseFloat(overtimeSalary)).toFixed(2);
        const OTRate = isSunday ? (salaryPerHour * 2).toFixed(2) : (salaryPerHour * 1.25).toFixed(2);

        return { overtimeSalary, totalSalary, overtimeHours, salaryPerHour, OTRate };
    };

    const returnOnLeave = () => {
        const overtimeSalary = 0.0, totalSalary = 0.0, overtimeHours = 0.0, salaryPerHour = 0.0, OTRate = 0.0, regularSalary = 0.0;
        return { overtimeSalary, totalSalary, overtimeHours, salaryPerHour, OTRate, regularSalary };
    }


    const convertTimeStringToNumeric = (timeString) => {
        const [hours] = timeString.split(':').map(Number);
        return hours;
    };

    const calculateTotalDaysInMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const totalDays = (lastDayOfMonth - firstDayOfMonth) / (1000 * 60 * 60 * 24) + 1;
        return totalDays;
    };

    //console.log(selectedDate);

    const handleChange = () => {
        console.log("change")
    }

    const cancelAdd = () => {
        console.log("cancelAdd");
    }

    return (
        <div className="w-full">
            <section className="bg-white dark:bg-gray-900">
                <div className='flex justify-between items-center bg-gray-100'>
                    <div className='text-right ml-4'>
                        Employee Attendance
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">                        
                        <tr>
                            <th colSpan={5} class="px-4 sm:px-6 py-3">
                                Select a Date :
                                <DatePicker
                                    selected={selectedDate}
                                    className="ml-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    onChange={handleDateChange}
                                />
                            </th>
                        </tr>
                            <tr>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    Employee
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    onLeave
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    In-Time
                                </th>
                                <th scope="col"class="px-3 sm:px-6 py-4">
                                    Out-Time
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    T/W/Hrs
                                </th>
                                <th scope="col" class="px-3 sm:px-6 py-4">
                                    Comments
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {shiftAttendanceAdd.length > 0 && (
                                shiftAttendanceAdd.map((ShifAtten, Index) => (
                                    <tr key={Index} class="border">
                                        <td scope="col" class="px-4 sm:px-6 py-3 whitespace-nowrap">
                                            {`${ShifAtten.firstName} ${ShifAtten.lastName}`}
                                        </td>
                                        <td class="px-3 sm:px-6 py-4">
                                            <input
                                                type="checkbox"
                                                id={`onLeave_${Index}`}
                                                checked={ShifAtten.onLeave}
                                                onChange={() => handleOnLeaveChange(!ShifAtten.onLeave, Index)}
                                                disabled={false}
                                            />
                                        </td>
                                        <td class="px-5 sm:px-8 py-4">
                                            <TimePicker
                                                format="hh:mm a"
                                                onChange={(time) => handleInTimeChange(time, Index)}
                                                value={ShifAtten.onLeave ? '00:00' : ShifAtten.inTime}
                                                disabled={ShifAtten.onLeave}
                                                disableClock={true}
                                            />
                                        </td>
                                        <td class="px-5 sm:px-8 py-4">
                                            <TimePicker
                                                format="hh:mm a"
                                                onChange={(time) => handleOutTimeChange(time, Index)}
                                                value={ShifAtten.onLeave ? '00:00 PM' : ShifAtten.outTime}
                                                disabled={ShifAtten.onLeave}
                                                disableClock={true}
                                            />
                                        </td>
                                        <td class="px-3 sm:px-6 py-4">
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                value={ShifAtten.onLeave ? '00:00' : ShifAtten.totalWorkedHrs || ''}
                                                readOnly
                                                disabled
                                            />
                                        </td>
                                        <td class="px-3 sm:px-6 py-4"> {/* Decreased px value for TotalWorkedHrs */}
                                            <textarea
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                placeholder="Comments"
                                                rows="1"
                                                cols={2}
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>                       
                    </table>
                    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-96 lg:w-full lg:grid lg:grid-cols-6 lg:gap-4">
                    <div class="flex items-center justify-end lg:col-span-6">
                        <button
                            class="bg-gray-100 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handlePostAttendance}
                        >
                            Save
                        </button>
                        <button
                            class="bg-black/50 ml-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={cancelAdd}
                        >
                            Cancel
                        </button>
                    </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default AttendanceAdd;