import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { addProductType, GetById, UpdateProductType, fetchProductTypes } from '../../../redux/Slice/productType';
import { useDispatch, useSelector } from 'react-redux';

const ProductTypeAdd = () => {

    const param = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ProductId, setProductId] = useState(param.ProductId)
    const [validateInput, setValidate] = useState('')
    const [productType1, setproductType] = useState({
        id: 0,
        title: "",   
        createdBy: 1,  
        isActive: true,
    }) 

    const { productType, loading, error } = useSelector((state) => state.productType);
   
    useEffect(() => {
        if(productType.product !== null)
        {
            setproductType(prev =>({
                ...prev,
                id: productType.product.id,
                title: productType.product.title,
                createdBy: productType.product.createdBy,
                isActive: productType.product.isActive
            }))
        }
    }, [productType.product])


    const handleAddProductType = () => {
        if (parseInt(productType1.id) === 0) {
            dispatch(addProductType(productType1));    
            dispatch(fetchProductTypes())       
            navigate("/productType");
        }
        else {
            dispatch(UpdateProductType(productType1));
            dispatch(fetchProductTypes());
            navigate("/productType");
        }
    }

    useEffect(()=>{
        const GetProdbyId = async () => {
            const response = await dispatch(GetById(ProductId))
            console.log(response);
        }
        if(parseInt(ProductId) > 0)
        {
            GetProdbyId();
        }

    },[ProductId])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setproductType((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    if (loading === 'loading') {
        return <p>Loading...</p>;
      }
    
      if (loading === 'failed') {
        return <p>Error: {error}</p>;
      }



  return (
    <div class="w-full">
    <section class="bg-white dark:bg-gray-900">
        <div className='flex justify-between items-center bg-gray-100'>
            <div className='text-right ml-4'>
                Product Type
            </div>
        </div>
        <div class="flex items-center justify-center bg-white">
            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-96 lg:w-full lg:grid lg:grid-cols-6 lg:gap-4">
                {/* <!-- Name --> */}
                <div class="mb-4 lg:col-span-2">
                    <label class="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Title
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="title"
                        name='title'
                        type="text"
                        placeholder="title"
                        autoComplete="off"
                        value={productType1.title}
                        onChange={handleChange}
                    />
                </div>

                {/* <!-- Button --> */}
                <div class="flex items-center lg:col-span-6">
                    <label class="block text-red-500 text-sm font-bold mr-2" htmlFor="message">
                        {validateInput}
                    </label>
                    <button
                        class="bg-gray-100 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleAddProductType}
                    >
                        save
                        {/* {employeeId === undefined || employeeId === 0 ? 'Save' : 'Update'} */}
                    </button>
                    <button
                        class="bg-black/50 ml-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => navigate('/productType')}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>

    </section>
</div>
  )
}

export default ProductTypeAdd;
