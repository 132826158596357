import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';

//Slice
import { addCustomer, EditCustomer } from '../../../redux/Slice/customerSlice';

const CustomerAdd = ({ setIsMoodAdding, CustomerId }) => {

    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.Customer);

    const [Customer, setCutomer] = useState({
        cusId: 0,
        cusName: "",
        cusAddress: "",
        cusAttentionTo: "",
        cusContactEmail: "",
        cusContactNumer: "",
        cusTRN: "",
        cusRegNo: "",
        cusCountry: 1,
        cusCity: 1,
        cusRegion: 1,
        cusLogo: "",
        createdBy: 1,
        createdOn: new Date(),
        isActive: true,
        user: null
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCutomer((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    const handleAddCustomer = async () => {
        console.log('clicked')
        const result = await dispatch(addCustomer(Customer));
        if (result.type.split('/')[2] === "fulfilled") {
            setIsMoodAdding(false);
        }
    }


    useEffect(() => {
        if (CustomerId > 0) {
            if (Array.isArray(data)) {
                const cust = data.find(x => x.cusId === CustomerId);
                if (cust) {
                    setCutomer(cust);
                } else {
                    console.log(`Customer with id ${CustomerId} not found.`);
                }
            } else {
                console.error('data is not an array.');
            }
        }
    }, [CustomerId, dispatch])


    return (
        <div class="w-full">
            <section class="bg-white dark:bg-gray-900">
                <div className='flex justify-between items-center bg-gray-100'>
                    <div className='text-right ml-4'>
                        Customer
                    </div>
                </div>
                <div class="flex items-center bg-white">
                <form class="shadow-md rounded px-8 pt-6 pb-8 mb-4 lg:w-full lg:grid lg:grid-cols-2 lg:gap-4">
                        {/* Name */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="name">
                                Name:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="name"
                                name='cusName'
                                type="text"
                                placeholder="Customer Name"
                                autoComplete="off"
                                value={Customer.cusName}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Attention To */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusAttentionTo">
                                Attention To:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusAttentionTo"
                                name="cusAttentionTo"
                                type="text"
                                placeholder="Customer Attention To"
                                autoComplete="off"
                                value={Customer.cusAttentionTo}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Address */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusAddress">
                                Address:
                            </label>
                            <textarea
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusAddress"
                                name="cusAddress"
                                placeholder="Customer Address"
                                autoComplete="off"
                                value={Customer.cusAddress}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        {/* Contact Phone */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusContactNumer">
                                Contact Phone:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusContactNumer"
                                name="cusContactNumer"
                                type="text"
                                placeholder="Customer Contact Phone"
                                autoComplete="off"
                                value={Customer.cusContactNumer}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Contact Mobile */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusContactMobile">
                                Contact Mobile:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusContactMobile"
                                name="cusContactMobile"
                                type="text"
                                placeholder="Customer Mobile"
                                autoComplete="off"
                                value={Customer.cusContactMobile}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Email */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusContactEmail">
                                Email:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusContactEmail"
                                name="cusContactEmail"
                                type="email"
                                placeholder="Customer Email"
                                autoComplete="off"
                                value={Customer.cusContactEmail}
                                onChange={handleChange}
                            />
                        </div>

                        {/* TRN */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="cusTRN">
                                TRN:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="cusTRN"
                                name="cusTRN"
                                type="text"
                                placeholder="Customer TRN"
                                autoComplete="off"
                                value={Customer.cusTRN}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Country */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="country">
                                Country:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="country"
                                name="country"
                                onChange={handleChange}
                            >
                                <option value="">Select Country</option>
                                <option value="1">UAE</option>
                            </select>
                        </div>

                        {/* Region */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="region">
                                Region:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="region"
                                name="region"
                                onChange={handleChange}
                            >
                                <option value="">Select Region</option>
                                <option value="1">Sharjah</option>
                                {/* Add your region options here */}
                            </select>
                        </div>

                        {/* Area */}
                        <div class="mb-4 lg:flex lg:items-center">
                            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="area">
                                Area:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-1"
                                id="area"
                                name='area'
                                onChange={handleChange}
                            >
                                <option value="0">Select Area</option>
                                <option value="1">Sharjah City</option>
                                <option value="2">Al Majaz</option>
                                <option value="3">Al Qasba</option>
                            </select>
                        </div>

                        {/* Button */}
                        <div class="flex">
                            <button
                                class="bg-gray-100 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
                                type="button"
                                onClick={() => handleAddCustomer()}
                            >
                                {CustomerId === 0 ? 'Save' : 'Update'}
                            </button>
                            <button
                                class="bg-black/50 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => setIsMoodAdding(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>

                </div>

            </section>
        </div>
    )
}

export default CustomerAdd;
