import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

//Slice
import { fetchAttendance } from '../../../redux/Slice/attendanceSlice';
//import { useNavigate } from 'react-router-dom';

const EmpAttendance = () => {

    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const { attendance, loading, error } = useSelector((state) => state.attendance);
 
    useEffect(() => {
        dispatch(fetchAttendance())
    }, [dispatch])

    // const AddNew = () => {
    //     navigate("/AttendanceAdd")
    // }

    const Edit = () => {
        console.log("Edit");
        alert('work in progress')
    }

          const PageSize = 10; 
          const [currentPage, setCurrentPage] = useState(1);
          const handlePageChange = (page) => {
              setCurrentPage(page);
          };
          const totalPages = Math.ceil(attendance.length / PageSize);
          const startIndex = (currentPage - 1) * PageSize;
          const endIndex = Math.min(startIndex + PageSize, attendance.length);
          const currentAttendance = attendance.slice(startIndex, endIndex);

    if (loading === 'loading') {
        return <p>Loading...</p>;
      }
    
      if (loading === 'failed') {
        return <p>Error: {error}</p>;
      }



    return (
        <div class="w-full overflow-x-auto">
            <section class="bg-white dark:bg-gray-900">
                <div className='flex justify-between items-center bg-gray-100 h-8'>
                    <div className='text-right ml-4'>
                        Employee Attendance
                    </div>
                    {/* <button className='inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1 mb-1 mr-1'
                        onClick={() => AddNew()}
                    >
                        Add New
                    </button> */}
                </div>
                
                <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                                Date
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                               Project
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                                Employee
                            </th>                           
                            <th scope="col" class="px-4 sm:px-6 py-3">
                                Shift
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            InTime          
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            OutTime         
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            T/W/H
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                             OT
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            Salary
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            Salary/Hrs
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                            OT/Hrs
                            </th>
                            <th scope="col" class="px-4 sm:px-6 py-3">
                           total Salary
                            </th>
                            <th scope="col" class="px-2 sm:px-6 py-4 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentAttendance.length > 0 && (
                            currentAttendance.map((atten, Index) => (
                                <tr key={Index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td class="px-2 sm:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {new Date(atten.attendanceDate).toLocaleString('en-US', {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}
                                    </td>
                                    <td class="sm:px-6 py-4 whitespace-nowrap dark:text-white">
                                        {/* {atten.projectNo} */}
                                        Project Name
                                    </td>
                                    <td class="px-2 sm:px-6 py-4 whitespace-nowrap dark:text-white">
                                        {`${atten.employee.firstName} ${atten.employee.lastName}`}
                                    </td>                                    
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.shift}
                                    </td>
                                    <td class="px-2 sm:px-6 py-4 whitespace-nowrap dark:text-white">
                                     {moment(atten.inTime, 'HH:mm:ss').format('h:mm A')}
                                    </td>
                                    <td class="px-2 sm:px-6 py-4 whitespace-nowrap dark:text-white">    
                                      {moment(atten.outTime, 'HH:mm:ss').format('h:mm A')}     
                                    </td>
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.totalWorkedHrs}
                                    </td>
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.ot}
                                    </td>
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.basicSalary}
                                    </td>     
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.salaryPerHour}
                                    </td>   
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.otRate}
                                    </td>  
                                    <td class="px-2 sm:px-6 py-4">
                                        {atten.totalSalary}
                                    </td>                                   
                                    <td className="px-2 py-4 text-center inline align-middle">
                                        <span href="#" className="text-blue-500 mx-2 hover:text-blue-700 cursor-pointer" title='Edit'
                                            onClick={() => Edit(atten.cusId)}>
                                            <svg
                                                className="w-4 h-4 inline-block align-text-bottom"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                ></path>
                                            </svg>                                            
                                        </span>
                                        <span href="#" className="text-green-500 mx-2 hover:text-green-700 cursor-pointer" title='View'>
                                            <svg
                                                className="w-4 h-4 inline-block align-text-bottom"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>                                            
                                        </span>
                                    </td>
                                </tr>
                            ))
                        )}

                    </tbody>
                </table>

                {/* Pagination controls */}
                <div className="flex justify-center my-4">
                    {[...Array(totalPages).keys()].map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page + 1)}
                            className={`px-4 py-2 mx-1 rounded-full ${
                                currentPage === page + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
                            }`}
                        >
                            {page + 1}
                        </button>
                    ))}
                </div>

                {/* <div class="fixed bottom-4 right-4">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        onClick={() => AddNew()}
                    >
                        +
                    </button>
                </div> */}
            </section>
        </div>
    )
}

export default EmpAttendance;