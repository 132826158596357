import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';

import DtTable from '../../table/form/dtTable';

 function InvoiceList() {

    const [data, setData] = useState([{Id:1,Name:"Akhlak" }]);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     const fetchData = async () => {
    //       setLoading(true);
    //       // Fetch data from your server
    //       const response = await fetch('your-api-endpoint');
    //       const jsonData = await response.json();
    //       setData(jsonData);
    //       setLoading(false);
    //     };
    
    //     fetchData();
    //   }, []);

    


     const generateData = (count) => {
         const data = [];

         for (let i = 0; i < count; i++) {
             const gender = Math.random() < 0.5 ? 'Male' : 'Female';
             data.push({
                 id: i + 1,
                 name: "Test Name",
                 gender: "Male",
                 position: "Hello",
                 phone: "131313213213",
             });
         }

         return data;
     };

     const sampleData = generateData(50);

     useEffect(() => {
         setData(sampleData);
     }, [sampleData])



      const columns = [
        // Define your columns here
        { Header: 'ID', accessor: 'id' },
        { Header: 'name', accessor: 'name' },
        { Header: 'gender', accessor: 'gender' },
        { Header: 'position', accessor: 'position' },
        { Header: 'phone', accessor: 'phone' },
      ];
    
      return (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <DtTable title={'Invoice List'}/>
          )}
        </div>
      );
}


export default InvoiceList;