import { createSlice } from '@reduxjs/toolkit';

export const AppSlice = createSlice({
    name: 'Auth',
    initialState: {
        Auth: false,
    },
    reducers: {
      login: (state) => {
        state.Auth = true;
      },
      logout: (state) => {       
        state.Auth = false;
      },
    },
  });

export const { login, logout } = AppSlice.actions;

export const SelectAuth = (state) => state.auth.Auth;

export default AppSlice.reducer;