import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchProjectById = createAsyncThunk('ProjectPDF/fetchProjectById', async (ProjectId) => {
    const response = await axios.post(`${API_BASE_URL}Project/GetbyId?ProjectId=`+ProjectId);
    return response.data;
  });


  const ProjectPDFSlice = createSlice({
    name: 'ProjectPDF',
    initialState: { ProjectPDF: {}, loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProjectById.pending, (state) => {
          state.loading = 'loading';
        })
        .addCase(fetchProjectById.fulfilled, (state, action) => {
          state.loading = 'succeeded';
          state.ProjectPDF = action.payload;
        })
        .addCase(fetchProjectById.rejected, (state, action) => {
          state.loading = 'failed';
          state.error = action.error.message;
        })       
    },
  });

  export default ProjectPDFSlice.reducer;