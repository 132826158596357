import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';

export const fetchData = createAsyncThunk('data/fetchData', async () => {
    const response = await axios.get(`${API_BASE_URL}User/All`);
    return response.data;
  });

  const dataSlice = createSlice({
    name: 'data',
    initialState: { datad: [], loadingd: 'idle', errord: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state) => {
          state.loadingd = 'loading';
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          state.loadingd = 'succeeded';
          state.datad = action.payload;
        })
        .addCase(fetchData.rejected, (state, action) => {
          state.loadingd = 'failed';
          state.errord = action.error.message;
        });
    },
  });

  export default dataSlice.reducer;