// Header.js
import React from 'react';
import { View, StyleSheet,Image } from '@react-pdf/renderer';

const fercoHeadingImage = require('../../../asset/images/fercoheadig.jpeg');

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 5,
    left: 15,
    right: 15,
    textAlign: 'center',
    backgroundColor: '#F0F0F0', 
  },
});

const Header = () => (
  <View style={styles.header}>
    <Image src={fercoHeadingImage} />
  </View>
);

export default Header;
