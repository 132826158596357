import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//CSS
import '../css/employee.css'
import { fetchEmployees } from '../../../redux/Slice/employeeSlice';

function Employee() {

  const dispatch = useDispatch();
  const { employee, loading, error } = useSelector((state) => state.employee);
  const [IsAddEditMode, setIsAddEditMode] = useState(false);
  const [employeeId, setemployeeId] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEmployees());  
  }, [dispatch]);

  if (loading === 'loading') {
    return <p>Loading...</p>;
  }

  if (loading === 'failed') {
    return <p>Error: {error}</p>;
  }

  const Edit = (employeeId) => {   
    navigate(`/employeeAdd/${employeeId}`)
  }


  return (
    <div class="w-full">
      <section class="bg-white dark:bg-gray-900">
        <div className='flex justify-between items-center bg-gray-100'>
          <div className='text-right ml-4'>
            Employees Information
          </div>
          <button className='inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
            rounded mt-1 mb-1 mr-1'
            onClick={() => navigate('/employeeAdd')}
          >
            Add New
          </button>
        </div>
        <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Full Name
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Contact
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Gender
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Employment
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Department
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Position
              </th>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Reporting to
              </th>
              <th scope="col" class="px-2 sm:px-6 py-4 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {employee.length > 0 && employee.map((data, Index) => (
              <tr key={Index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-4 sm:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {`${data.firstName} ${data.lastName}`}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.phoneNumber}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.gender}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.employment}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.departmentID}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.designation.desName}
                </td>
                <td class="px-4 sm:px-6 py-4">
                  {data.supervisorID}
                </td>
                <td className="px-2 sm:px-6 py-4 text-center">
                  <a href="#" className="text-blue-500 mx-2 hover:text-blue-700"
                    onClick={() => Edit(data.employeeID)}>
                    <svg
                      className="w-4 h-4 inline-block align-text-bottom"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                    Edit
                  </a>
                  <a href="#" className="text-green-500 mx-2 hover:text-green-700">
                    <svg
                      className="w-4 h-4 inline-block align-text-bottom"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                    View
                  </a>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
        <div class="fixed bottom-4 right-4">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => navigate('/employeeAdd')}>
            +
          </button>
        </div>
      </section>
    </div>
  )
}
export default Employee;