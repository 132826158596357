// AttendanceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import API_BASE_URL from '../../apiConfig';
const username = '11160922';
const password = '60-dayfreetrial';
const encodedCredentials = btoa(`${username}:${password}`);


export const fetchAttendance = createAsyncThunk('Attendance/Attendance', async () => {
  const response = await axios.get(`${API_BASE_URL}Attendance/All`, {
  method:'get',
  headers: {
      'Authorization': `Basic ${encodedCredentials}`,
      'Content-Type': 'application/json'
  }})
  return response.data;
});

export const addAttendance = createAsyncThunk('Attendance/addAttendance', async (newAttendance) => {
  const response = await axios.post(`${API_BASE_URL}Attendance/Add`, newAttendance,{
      method:'post',
      headers: {
          'Authorization': `Basic ${encodedCredentials}`,
          'Content-Type': 'application/json'
      }});
  return response.data;
});

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: { attendance: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendance.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.attendance = action.payload;
      })
      .addCase(fetchAttendance.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addAttendance.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(addAttendance.fulfilled, (state, action) =>{
        state.loading = "succeeded"
        state.attendance = action.payload;     
      })
      .addCase(addAttendance.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      });
  },
});

export default attendanceSlice.reducer;
