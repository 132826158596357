import React, { useEffect, useState } from 'react'

import { addEmployee, fetchEmployees } from '../../../redux/Slice/employeeSlice';
import { fetchDepartments } from '../../../redux/Slice/departmentSlice';
import { fetchDesignation } from '../../../redux/Slice/designationSlice';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const EmployeeAdd = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [validateInput, setValidate] = useState('')

    const [employeeId, setemployeeId] = useState(params.employeeId);

    const { department } = useSelector((state) => state.department);
    const { designation } = useSelector((state) => state.designation);
    const { employee } = useSelector((state) => state.employee);
    
    const Loaddata = async () => {
        await dispatch(fetchDesignation());
        await dispatch(fetchDepartments());
        await dispatch(fetchEmployees());
    }

    useEffect(() => {
        Loaddata();
    }, [dispatch])

    const [employeeData, setEmployee] = useState({
        employeeID: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: "2024-01-25T16:27:31.692Z",
        gender: "Male",
        address: "",
        phoneNumber: "",
        email: "",
        departmentID: 1,
        position: 1,
        salary: 2000,
        ireDate: "2024-01-25T16:27:31.692Z",
        supervisorID: 0,
        passportExpiry: "2024-01-25T16:27:31.692Z",
        visaExpiry: "2024-01-25T16:27:31.692Z",
        drivingLicienceExpiry: "2024-01-25T16:27:31.692Z",
        nationality: 0,
        employment: "Full-Time",
        createdBy: 1,
        createdOn: "2024-01-25T16:27:31.692Z",
        isActive: true,
    })

    useEffect(() => {        
        if (employeeId) {
            const Emp = employee.filter(x=>x.employeeID === parseInt(employeeId));           
            const EmpObje = Emp[0];           
            setEmployee((prev) => {
                return{
                    ...prev,
                    employeeID: EmpObje.employeeID,
                    firstName: EmpObje.firstName,
                    lastName: EmpObje.lastName,
                    address: EmpObje.address,
                    salary: EmpObje.salary,
                    departmentID : EmpObje.departmentID,
                    gender : EmpObje.gender,
                    phoneNumber: EmpObje.phoneNumber,
                    dateOfBirth: EmpObje.dateOfBirth,
                    email: EmpObje.email,
                    ireDate: employee.ireDate,
                    nationality: EmpObje.nationality,
                    employment: EmpObje.employment
                }
            })
        }
    }, [employeeId, employee]);

    const handleAddEmployee = async () => {

        try {
            const result = await dispatch(addEmployee(employeeData));
            const checkSuccess = result.type.split('/')[2];
            if (checkSuccess === 'rejected') {
                setValidate(result.payload);
            }
            else if (checkSuccess === "fulfilled") {
                if (result.payload === 'Validation Failed') {
                    setValidate(result.payload);
                }
                else {
                    setValidate('');
                    const response = await dispatch(fetchEmployees());
                    const checkresponse = response.type.split('/')[2];
                    if (checkresponse === "fulfilled") {
                        navigate('/employee');
                    }
                    else {
                        console.log(result);
                    }
                }
            }
            else {
                setValidate('');
                result = await dispatch(fetchEmployees());
                console.log(result);
                checkSuccess = result.type.split('/')[2];
                if (checkSuccess === "fulfilled") {
                    if (result.payload === 'Validation Failed') {
                        navigate('/employee');
                    }
                }
                else {
                    console.log(result);
                }

            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEmployee((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    console.log(employeeData);


    return (
        <div class="w-full">
            <section class="bg-white dark:bg-gray-900">
                <div className='flex justify-between items-center bg-gray-100'>
                    <div className='text-right ml-4'>
                        Employee
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <form class="shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-96 lg:w-full lg:grid lg:grid-cols-2 lg:gap-4">
                        {/* First Name */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2  w-1/4" htmlFor="firstName">
                                First Name:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="firstName"
                                name='firstName'
                                type="text"
                                placeholder="Employee First Name"
                                autoComplete="off"
                                value={employeeData.firstName}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Last Name */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="lastName">
                                Last Name:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder="Employee Last Name"
                                autoComplete="off"
                                value={employeeData.lastName}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Phone Number */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="phoneNumber">
                                Phone Number:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                placeholder="Employee Phone Number"
                                autoComplete="off"
                                value={employeeData.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Email */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="email">
                                Email:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Employee Email"
                                autoComplete="off"
                                value={employeeData.email}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Gender */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="gender">
                                Gender:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="gender"
                                name="gender"
                                value={employeeData.gender}
                                onChange={handleChange}
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                        </div>

                        {/* Employment */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="employment">
                                Employment:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="employment"
                                name="employment"
                                type="text"
                                autoComplete="off"
                                value={employeeData.employment}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Department */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="departmentID">
                                Department:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="departmentID"
                                name="departmentID"
                                value={employeeData.departmentID}
                                onChange={handleChange}
                            >
                                {department && department.length ? (
                                    department.map(Dep => (
                                        <option key={Dep.depId} value={Dep.depId}>
                                            {Dep.depName}
                                        </option>
                                    ))
                                ) : (
                                    <option value="0">Select Department</option>
                                )}
                            </select>
                        </div>

                        {/* Section */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="section">
                                Section:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="section"
                                name="section"
                                type="text"
                                autoComplete="off"
                                value='not implemented'
                                onChange={handleChange}
                            />
                        </div>

                        {/* Position */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="position">
                                Position:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="position"
                                name="position"
                                value={employeeData.position}
                                onChange={handleChange}
                            >
                                {designation && designation.length ? (
                                    designation.map(Des => (
                                        <option key={Des.desId} value={Des.desId}>
                                            {Des.desName}
                                        </option>
                                    ))
                                ) : (
                                    <option value="0">Select Position</option>
                                )}
                            </select>
                        </div>

                        {/* Basic Salary */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="salary">
                                Basic Salary:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="salary"
                                name="salary"
                                type="text"
                                autoComplete="off"
                                value={employeeData.salary}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Reporting To */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="supervisorID">
                                Reporting To:
                            </label>
                            <select
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="supervisorID"
                                name="supervisorID"
                                value={employeeData.supervisorID}
                                onChange={handleChange}
                            >
                                {employee && employee.length ? (
                                    employee.map(Emp => (
                                        <option key={Emp.employeeID} value={Emp.employeeID}>
                                            {`${Emp.firstName} ${Emp.lastName}`}
                                        </option>
                                    ))
                                ) : (
                                    <option value="0">Select Position</option>
                                )}
                            </select>
                        </div>

                        {/* Nationality */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="nationality">
                                Nationality:
                            </label>
                            <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="nationality"
                                name="nationality"
                                type="text"
                                autoComplete="off"
                                value={employeeData.nationality}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Address */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="address">
                                Address:
                            </label>
                            <textarea
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="address"
                                name="address"
                                placeholder="Employee Address"
                                autoComplete="off"
                                value={employeeData.address}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        {/* Description */}
                        <div class="mb-4 lg:col-span-1 flex items-center">
                            <label class="block text-gray-700 text-sm font-bold mr-2 w-1/4" htmlFor="description">
                                Description:
                            </label>
                            <textarea
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="description"
                                name="description"
                                placeholder="Brief Description"
                                autoComplete="off"
                                value="not implemented"
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        {/* Button */}
                        <div class="flex items-center justify-end lg:col-span-2">
                            <label class="block text-red-500 text-sm font-bold mr-2" htmlFor="message">
                                {validateInput}
                            </label>
                            <button
                                class="bg-gray-100 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={handleAddEmployee}
                            >
                                {employeeId === undefined || employeeId === 0 ? 'Save' : 'Update'}
                            </button>
                            <button
                                class="bg-black/50 ml-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => navigate('/employee')}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default EmployeeAdd;
