import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';

export const fetchCustomers = createAsyncThunk('user/fetchCustomers', async () => {
    const response = await axios.get(`${API_BASE_URL}Customer/All`);
    return response.data;
  });

  export const addCustomer = createAsyncThunk('user/addCustomer', async (newCustomer) => {
    const response = await axios.post(`${API_BASE_URL}Customer/Add`, newCustomer);
    return response.data;
  });

  export const EditCustomer = createAsyncThunk('user/EditCustomer', async (newCustomer) => {
    const response = await axios.post(`${API_BASE_URL}Customer/Edit`, newCustomer);
    return response.data;
  });


  const customerSlice = createSlice({
    name: 'customer',
    initialState: { data: [], loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCustomers.pending, (state) => {
          state.loading = 'loading';
        })
        .addCase(fetchCustomers.fulfilled, (state, action) => {
          state.loading = 'succeeded';
          state.data = action.payload;
        })
        .addCase(fetchCustomers.rejected, (state, action) => {
          state.loading = 'failed';
          state.error = action.error.message;
        })
        .addCase(addCustomer.pending, (state) => {
          state.loading = 'loading';
        })
        .addCase(addCustomer.fulfilled, (state, action) => {
          state.loading = 'succeeded';
          state.data = action.payload;             
        })
        .addCase(addCustomer.rejected, (state, action) => {
          state.loading = 'failed';
          state.error = action.error.message;
        })
        .addCase(EditCustomer.pending, (state) => {
            state.loading = 'loading';
          })
          .addCase(EditCustomer.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.data = action.payload;            
          })
          .addCase(EditCustomer.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error.message;
          });
    },
  });

  export default customerSlice.reducer;
  