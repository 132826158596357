import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchQuotationVm = createAsyncThunk('quotationVm/fetchQuotationVm', async (quotId) => {
    const response = await axios.post(`${API_BASE_URL}Quotation/GetById?QuotId=`+quotId);
    return response.data;
  });

  
  const quotationVmSlice = createSlice({
    name: 'quotationVm',
    initialState: { quotationVm: {}, loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchQuotationVm.pending, (state) => {
          state.loading = 'loading';
        })
        .addCase(fetchQuotationVm.fulfilled, (state, action) => {
          state.loading = 'succeeded';
          state.quotationVm = action.payload;
        })
        .addCase(fetchQuotationVm.rejected, (state, action) => {
          state.loading = 'failed';
          state.error = action.error.message;
        })       
    },
  });

  export default quotationVmSlice.reducer;
  