import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux';

import { fetchData } from '../../../redux/Slice/dataSlice';
import { fetchUsers, addUser } from '../../../redux/Slice/userSlice';

import DtTable from '../../table/form/dtTable';

 const User = () => {

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.Users);
  const { datad, loadingd, errord } = useSelector((state) => state.data);

  useEffect(()=>{  
    dispatch(fetchData());
    dispatch(fetchUsers());
  }, [dispatch])

  const [userTable, setUserTable] = useState({    
      "id": "0",
      "fullName": "React",
      "userName": "React",
      "email": "React",
      "password": "React",
      "passwordHash": "React",
      "imagUrl": "React",
      "genderId": 1,
      "roleId": 1,
      "createdOn": "2024-01-19T16:46:04.85Z",
      "isActive": true,
      "role": {
        "roleId": 0,
        "roleName": "string",
        "createdBy": 0,
        "createdOn": "2024-01-19T16:46:04.85Z",
        "isActive": true      
    }
 });


  const AddNewUser = () => {
    dispatch(addUser(userTable));   

  }
  // console.log(data);

  const handleEdit = (row) => {
    console.log(row)
  }

  const columns = [  
    {
      name: 'Full Name',
      selector: row => row.fullName,
      sortable: true,
      
    },
    {
      name: 'User Name',
      selector: row => row.userName,
      sortable: true,
      
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      
    },
    {
      name: 'CreatedOn',
      selector: row => row.createdOn,
      sortable: true,
      
    },      
    {
      name: 'IsActive',
      selector: row => row.isActive === true ? "Yes" : "No",
      sortable: true,
      
    },
    {
      name: 'Actions',
      cell: row => (
        <div class="inline-flex">
          <span href="#" className="text-blue-500 mx-2 hover:text-blue-700 cursor-pointer" title='Edit'
            onClick={() => handleEdit(row)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </span>        
        </div>
      )
    }

  ];

  const loadMore = (page) => {
    console.log(page);
}


  if (loading === 'loading') {
    return <p>Loading...</p>;
  }

  if (loading === 'failed') {
    return <p>Error: {error}</p>;
  }

    //console.log(datad);

  return (
    <div>
     <DtTable records={data} columns={columns} title={'User List'} length={data.length} AddNew={'none'} loadMore={(Page) => loadMore(Page)} />
    </div>
  )
}

export default User;