// TopNavbar.js
import React from 'react';
import { motion } from 'framer-motion';
import UserThumb from '../../../asset/images/userThmbnail.png'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/Slice/appSlice'
import {UseLogOut } from '../../../redux/Slice/userLogin'
import { useNavigate } from 'react-router-dom';


const TopNavbar = ({ element }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.Login);

  const handleSelectChange = (e) => {
    const newvalue = e.target.value;
    if (newvalue === 'fullName') {
      return true;
    }
    else if (newvalue === 'Logout') {
      dispatch(UseLogOut());
      dispatch(logout());
      navigate('/login');
    }
  } 

  return (

    <div class="w-full">
      <nav class="bg-white dark:bg-gray-900  z-20 top-0 border-b border-gray-200 dark:border-gray-600 ">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

          <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center relative">
            <input type="text" id="search-navbar" class="block p-2 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-4 w-4 absolute right-3 text-gray-500 dark:text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-5.2-5.2"></path>
              <circle cx="10" cy="10" r="8"></circle>
            </svg>
          </div>

          <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <div class="relative">
              <select class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              onChange={handleSelectChange}
              >
                <option value='fullName'>{data.fullName}</option>
                <option value='Logout'>Logout</option>                
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                {/* <!-- You can replace the arrow with a custom icon --> */}
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-8-8 1.5-1.5L10 9l6.5-6.5L18 4z" /></svg>
              </div>
            </div>
            <a href="http://www.w3.org/2000/svg" class="flex items-center">
              <img src={UserThumb} class="h-8" alt="UserImg" />
            </a>
          </div>

        </div>
      </nav>

      <motion.div className="flex flex-col mt-2">{element}</motion.div>
    </div>

  );
};

export default TopNavbar;
