import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

// * React icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosPerson } from 'react-icons/io';
import { SlSettings } from "react-icons/sl";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { IoMdCalendar } from 'react-icons/io';
import { IoMdPeople } from 'react-icons/io';
import { IoIosBriefcase } from 'react-icons/io';
import { IoIosChatboxes } from 'react-icons/io';
import { IoIosClipboard } from 'react-icons/io';
import { IoIosConstruct } from 'react-icons/io';
import { IoIosDocument } from 'react-icons/io';
import { HiOutlineDatabase } from "react-icons/hi";
import { TbReportAnalytics } from "react-icons/tb";
import { RiBuilding3Line } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";

//Asset
import FercoLogo from '../../../asset/images/FercoImages.jpg'
//Components
// import Employee from "../../Employee/from/employee";
import SubMenu from "./SubMenu";


function SideBar() {

  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();

  const location = useLocation();
  const { pathname, search, hash } = location;

  const subMenusList = [
    {
      name: "Product",
      icon: RiBuilding3Line,
      menus: ["Unit", "app settings", "stroage", "hosting"],
    },
    {
      name: "analytics",
      icon: TbReportAnalytics,
      menus: ["dashboard", "realtime", "events"],
    },
  ];

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false); 
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
      open: {
        x: 0,
        width: "16rem",
        transition: {
          damping: 40,
        },
      },
      closed: {
        x: -250,
        width: 0,
        transition: {
          damping: 40,
          delay: 0.15,
        },
      },
    }
    : {
      open: {
        width: "16rem",
        transition: {
          damping: 40,
        },
      },
      closed: {
        width: "4rem",
        transition: {
          damping: 40,
        },
      },
    };


  return (
    <div>
       <div 
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" bg-white text-gray shadow-xl z-[999] max-w-[16rem]  w-[16rem] 
            overflow-hidden md:relative fixed
         h-screen "
      >

        <div className="flex items-center gap-2.5 font-medium border-b py-3 border-slate-300  mx-3">
          <img
            src={FercoLogo}
            width={45}            
            alt=""
          />
          <span className="text-xl whitespace-pre">   FERCO</span>
        </div>

        <div className="flex flex-col  h-full">
          
            <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[68%] h-[70%]">
            {/* <li>
                <NavLink to="Quotation" className="link">
                  <AiOutlineAppstore size={23} className="min-w-max" />
                  Quotation
                </NavLink>
              </li> */}             
              <li>
                <NavLink to="/department" className="link">
                  <IoMdPeople size={23} className="min-w-max" />
                  Department
                </NavLink>
              </li>
              <li>
                <NavLink to="/designation" className="link">
                  <IoIosBriefcase size={23} className="min-w-max" />
                  Position
                </NavLink>
              </li>
              <li>
                <NavLink to="/employee" className="link">
                  <BsPerson size={23} className="min-w-max" />
                  Employee
                </NavLink>
              </li>
              <li>
                <NavLink to="/EmpAttendance" className="link">
                  <IoMdCalendar size={23} className="min-w-max" />
                  Attendance
                </NavLink>
              </li>
              <li>
                <NavLink to="/productType" className="link">
                  <HiOutlineDatabase size={23} className="min-w-max" />
                 ProductType
                </NavLink>
              </li>              
              <li>
                <NavLink to="/customer" className="link">
                  <IoIosPerson size={23} className="min-w-max" />
                  Customer
                </NavLink>
              </li>
              <li>
                <NavLink to="Quotation" className="link">
                  <IoIosChatboxes size={23} className="min-w-max" />
                  Quotation
                </NavLink>
              </li>
              <li>
                <NavLink to="QuotationAdd/0" className="link">
                  <IoIosChatboxes size={23} className="min-w-max" />
                  Quotation Add
                </NavLink>
              </li>
              <li>
                <NavLink to="Project" className="link">
                  <IoIosConstruct size={23} className="min-w-max" />
                  Project
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="MyDocument" className="link">
                  <IoIosConstruct size={23} className="min-w-max" />
                  MyDocument
                </NavLink>
              </li> */}
              <li>
                <NavLink to="Invoice" className="link">
                  <IoIosDocument size={23} className="min-w-max" />
                  Invoice
                </NavLink>
              </li>
              <li>
                <NavLink to="/Users" className="link">
                  <HiOutlineDatabase size={23} className="min-w-max" />
                 Users
                </NavLink>
              </li>
              {/* {(open || isTabletMid) && (
                <div className="border-y py-5 border-slate-300 ">
                  <small className="pl-3 text-slate-500 inline-block mb-2">
                    Product categories
                  </small>
                  {subMenusList?.map((menu) => (
                    <div key={menu.name} className="flex flex-col gap-1">
                      <SubMenu data={menu} />
                    </div>
                  ))}
                </div>
              )} */}
              {/* <li>
                <NavLink to={"/settings"} className="link">
                  <SlSettings size={23} className="min-w-max" />
                  Settings
                </NavLink>
              </li> */}
            </ul>       
        </div>
        <motion.div
          onClick={() => {
            setOpen(!open);
          }}
          animate={
            open
              ? {
                x: 0,
                y: 0,
                rotate: 0,
              }
              : {
                x: -10,
                y: -200,
                rotate: 180,
              }
          }
          transition={{ duration: 0 }}
          className="absolute w-fit h-fit md:block z-50 hidden right-2 bottom-3 cursor-pointer"
        >
          <IoIosArrowBack size={25} />
        </motion.div>
      </motion.div>
      
      <div className="m-3 md:hidden" onClick={() => setOpen(true)}>
        <MdMenu size={25} />
      </div>
      
    </div>
  )
}

export default SideBar