import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
  uploading: false,
  erroruploads: null,
};

const uploadSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addFile: (state, action) => {
      state.files.push(action.payload);
    },
    removeFile: (state, action) => {
      state.files = state.files.filter(file => file !== action.payload);
    },
    startUpload: state => {
      state.uploading = true;
      state.erroruploads = null;
    },
    uploadSuccess: state => {
      state.uploading = false;
      state.files = [];
    },
    uploadFailure: (state, action) => {
      state.uploading = false;
      state.erroruploads = action.payload;
    },
  },
});

export const { addFile, removeFile, startUpload, uploadSuccess, uploadFailure } = uploadSlice.actions;

export default uploadSlice.reducer;
