// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await axios.get(`${API_BASE_URL}User/All`);
  return response.data;
});

export const addUser = createAsyncThunk('user/addUser', async (newUser) => {
  const response = await axios.post(`${API_BASE_URL}User/Add`, newUser);
  return response.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState: { data: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload; 
        console.log("New User Added");      
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectUsers = (state) => state.user.data;

//export { fetchUsers, addUser };
export default userSlice.reducer;
