import { configureStore, combineReducers  } from "@reduxjs/toolkit";  
import dataSlice from '../Slice/dataSlice';
import employeeSlice from '../Slice/employeeSlice';
import User from '../Slice/userSlice';
import Login from "../Slice/userLogin";
import appSlice from '../Slice/appSlice';
import customerSlice from "../Slice/customerSlice";
import DepartmentSlice from "../Slice/departmentSlice";
import designationSlice from "../Slice/designationSlice";
import attendanceSlice from "../Slice/attendanceSlice";
import shiftAttendance from "../Slice/shiftAttendance";
import productType from "../Slice/productType";
import quotation from "../Slice/quotationSlice";
import uploadFile from "../Slice/uploadFile";
import quotationRef from "../Slice/quotationRefSlice";
import quotationVm from "../Slice/quotationVMSlice";
import project from "../Slice/projectSlice";
import ProjectPDF from "../Slice/projectSlicePDF";
import invoice from "../Slice/invoiceSlice"


// Combine reducers
const rootReducer = combineReducers({    
    data: dataSlice,
    employee: employeeSlice,
    Users: User,
    Login: Login,
    Auth: appSlice,
    Customer: customerSlice,
    department: DepartmentSlice,
    designation: designationSlice,
    attendance: attendanceSlice,
    shiftAttendance: shiftAttendance,
    productType: productType,
    quotation: quotation,
    files: uploadFile,
    quotationRef: quotationRef,
    quotationVm : quotationVm,
    project: project,
    ProjectPDF: ProjectPDF,
    invoice: invoice
  });

const Store = configureStore({
    reducer: rootReducer
});

export default Store;