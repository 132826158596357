// ShiftAttendanceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';

export const fetchShiftAttendanc = createAsyncThunk('ShiftAttendanc/fetchShiftAttendanc', async () => {
  const response = await axios.post(`${API_BASE_URL}Attendance/GetEmployeeBySuperviser`, {Id: 1});
  return response.data;
});

export const addShiftAttendance = createAsyncThunk('ShiftAttendanc/addShiftAttendance', async (newShiftAttendance) => {
  const response = await axios.post(`${API_BASE_URL}Attendance/Add`,  newShiftAttendance);
  return response.data;
});

const ShiftAttendanceSlice = createSlice({
  name: 'shiftAttendance',
  initialState: { shiftAttendance: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftAttendanc.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchShiftAttendanc.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.shiftAttendance = action.payload;
      })
      .addCase(fetchShiftAttendanc.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addShiftAttendance.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(addShiftAttendance.fulfilled, (state, action) =>{
        state.loading = "succeeded"
        state.shiftAttendance = action.payload;     
      })
      .addCase(addShiftAttendance.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      });
  },
});

export default ShiftAttendanceSlice.reducer;
