// ShiftAttendanceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const fetchProductTypes = createAsyncThunk('ProductType/fetchProductTypes', async () => {
  const response = await axios.get(`${API_BASE_URL}ProductType/All`); 
  return response.data;
});

export const addProductType = createAsyncThunk('ProductType/addProductType', async (ProductType) => {
  const response = await axios.post(`${API_BASE_URL}ProductType/Add`,  ProductType);
  return response.data;
});


export const GetById = createAsyncThunk('ProductType/GetById', async (Id) => {
  const response = await axios.post(`${API_BASE_URL}ProductType/GetById?Id=${Id}`,  {});
  return response.data;
});


export const UpdateProductType = createAsyncThunk('ProductType/UpdateProductType', async (ProductType) => {
  const response = await axios.post(`${API_BASE_URL}ProductType/Update`,  ProductType);
  return response.data;
});


const ProductTypeSlice = createSlice({
  name: 'productType',
  initialState: { 
    productType: { productType : [], loading: 'idle', error: null, product: null }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductTypes.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchProductTypes.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.productType.productType = action.payload;
      })
      .addCase(fetchProductTypes.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(addProductType.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(addProductType.fulfilled, (state, action) =>{
        state.loading = "succeeded"         
      })
      .addCase(addProductType.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      })
      .addCase(GetById.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(GetById.fulfilled, (state, action) =>{
        state.loading = "succeeded"        
        state.productType.product =  action.payload
      })
      .addCase(GetById.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      })
      .addCase(UpdateProductType.pending,(state) =>{
        state.loading = "loading"        
      })
      .addCase(UpdateProductType.fulfilled, (state, action) =>{
        state.loading = "succeeded" 
        state.productType.product = null        
      })
      .addCase(UpdateProductType.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      });
  },
});

export default ProductTypeSlice.reducer;
