import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../../apiConfig";

export const fetchDesignation = createAsyncThunk('Designation/fetchDesignation', async () => {
    const response = await axios.get(`${API_BASE_URL}Designation/All`);
    return response.data;
  });


const designationSlice = createSlice({
    name: 'designation', 
    initialState: { designation: [], loading: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDesignation.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchDesignation.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.designation = action.payload;
            })
            .addCase(fetchDesignation.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export default designationSlice.reducer;