import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProductTypes } from '../../../redux/Slice/productType';


const ProductType = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { productType, loading, error } = useSelector((state) => state.productType.productType);   
    const [productTypes, setproductTypes] = useState([]);

    useEffect(() => {
        dispatch(fetchProductTypes());  
      }, [dispatch]);

      useEffect(()=>{      
        setproductTypes(productType);
      },[productType])
    
      if (loading === 'loading') {
        return <p>Loading...</p>;
      }
    
      if (loading === 'failed') {
        return <p>Error: {error}</p>;
      }
    
      const Edit = (productTypeId) => {   
        navigate(`/ProductTypeAdd/${productTypeId}`)
      }
    
   
  return (
    <div class="w-full">
      <section class="bg-white dark:bg-gray-900">
        <div className='flex justify-between items-center bg-gray-100'>
          <div className='text-right ml-4'>
            ProductType Information
          </div>
          <button className='inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 
            rounded mt-1 mb-1 mr-1'
            onClick={() => navigate('/ProductTypeAdd/0')}
          >
            Add New
          </button>
        </div>
        <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-4 sm:px-6 py-3">
                Title
              </th>              
              <th scope="col" class="px-2 sm:px-6 py-4 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {productTypes.length > 0 && productTypes.map((data, Index) => (
              <tr key={Index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-4 sm:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {data.title} 
                </td>                
                <td className="px-2 sm:px-6 py-4 text-center">
                  <a href="#" className="text-blue-500 mx-2 hover:text-blue-700"
                    onClick={() => Edit(data.id)}>
                    <svg
                      className="w-4 h-4 inline-block align-text-bottom"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                    Edit
                  </a>
                  <a href="#" className="text-green-500 mx-2 hover:text-green-700">
                    <svg
                      className="w-4 h-4 inline-block align-text-bottom"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                    View
                  </a>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
        <div class="fixed bottom-4 right-4">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => navigate('/ProductTypeAdd')}>
            +
          </button>
        </div>
      </section>
    </div>
  )
}


export default ProductType;