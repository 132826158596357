import React from 'react';
import SideBar from '../../sideBar/form/sideBar';
import Topbar from '../../TopBar/form/NavtopBar';

import { Outlet } from 'react-router-dom';

function Mainlayout() {
   return (
    
    <div className="flex gap-5">
      <SideBar />   
      <Topbar element={  <Outlet />}/>      
    </div>
  );
}

export default Mainlayout;