
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from '../forms/myDocument'

const PDFViewerComponent = () => (
    <PDFViewer width="100%" height="800px">
      <MyDocument />
    </PDFViewer>
  );
  
  export default PDFViewerComponent;