// PDFViewer.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Footer from './docFooter';
import Header from './header';



const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
    },
    section: {
        flexDirection: 'row',        
        flexGrow: 1,
        marginTop: 70
    },
});

const MyDocument = () => (
    <Document style={{ width: '100%', height: '100vh' }}>
        {[1, 2].map((pageNumber) => (
        <Page key={pageNumber}  size="A4" style={styles.page}>
             <Header />
            <View style={styles.section}>
           
                <Text>PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    valuablePDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    valuablePDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    PDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                    valuablePDFViewer takes one prop pdfURL which can be set to your PDF location (this can be an online PDF location as well as long as CORS is allowed).
                </Text>               
            </View>     
            <Footer />     
        </Page>
        ))}
        {/* Add more pages if needed */}
    </Document>
);

export default MyDocument;
