import React, { useEffect, useState }  from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchInvoices } from '../../../redux/Slice/invoiceSlice';

import DtTable from '../../table/form/dtTable';
 const Invoice = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [invoices, setinvoices] = useState([]);
    const [length, setlength] = useState(10);
    const { invoice, loading, error } = useSelector((state) => state.invoice.invoice);

   
    // useEffect(() => {     
    //   setlength(invoices.length);
    // }, [invoices])


    useEffect(() => {
        setinvoices(invoice);
        //setlength(invoice.length);
      }, [invoice])
    
      useEffect(()=>{
        dispatch(fetchInvoices());
      },[dispatch])

  
      const AddNew = () => {
        navigate("/InvoiceAdd/0")
    }

    const handleEdit= (row) => {     
      navigate("/InvoiceEdit/"+row.invoiceId)
    }
    const handleDelete= (row) => {
      console.log(row)
    }

    const loadMore = (page) => {
      console.log(page);
  }

  const columns = [        
    {
      name: 'Invoice Date',
      selector: row => row.invoiceDate,
    sortable: true,
    format: row => {
      // Format the invoice date
      return new Date(row.invoiceDate).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
      
    },  
    {
      name: 'Ref No',
      selector: row => row.invoiceRefNo,
      sortable: true,
      
    },   
    {
      name: 'Project',
      selector: row => row.projectName,
      sortable: true,
      
    },
    {
      name: 'Customer',
      selector: row => row.customer.cusName,
      sortable: true,
      
    },
    {
      name: 'Sales',
      selector: row => row.employee.firstName + ' ' +row.employee.lastName,
      sortable: true,
      
    },
    {
      name: 'Sub Total',
      selector: row => row.subTotal,
      sortable: true,
      
    },
    {
      name: 'VAT',
      selector: row => row.vatAmount,
      sortable: true,
      
    },
    {
      name: 'Grand Totla',
      selector: row => row.grandTotal,
      sortable: true,
      
    },
    {
      name: 'Actions',
      cell: row => (
        <div>
          <button onClick={() => handleEdit(row)}>Edit</button> | <button onClick={() => handleDelete(row)}>View</button> | <button onClick={() => handleDelete(row)}>Delete</button>
          {/* Add more buttons as needed */}
        </div>
      )
    }
    
  ];

  if (loading === 'loading') {
    return <p>Loading...</p>;
  }

  if (loading === 'failed') {
    return <p>Error: {error}</p>;
  }

      return(
          <DtTable records={invoices} columns={columns} title={'Invoice List'} length={length} AddNew={AddNew} loadMore={() => loadMore(1)} currentPage={1}/>
      )
}

export default Invoice;