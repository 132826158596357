import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

//Slice
import { fetchCustomers } from '../../../redux/Slice/customerSlice';

//Components
import CustomerAdd from './customerAdd';

const Customer = () => {

    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.Customer);
    const [IsModeAdding, setIsMoodAdding] = useState(false);
    const [CustomerId, setCustomerId] = useState(0);


    useEffect(() => {
        dispatch(fetchCustomers());
    }, [dispatch])


    const Edit = (cusId) => {
        setCustomerId(cusId);
        setIsMoodAdding(true);
    }

    const AddNew = () => {
        setCustomerId(0)
        setIsMoodAdding(true);
    }

    return (

        !IsModeAdding ? (

            <div class="w-full">
                <section class="bg-white dark:bg-gray-900">
                    <div className='flex justify-between items-center bg-gray-100'>
                        <div className='text-right ml-4'>
                            Customer Information
                        </div>
                        <button className='inline bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1 mb-1 mr-1'
                            onClick={() => AddNew()}
                        >
                            Add New
                        </button>
                    </div>
                    <table class="w-full text-sm text-left rtl:text-right dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    Customer Name
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    Attention To
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    mobile
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    TRN
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" class="px-4 sm:px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" class="px-2 sm:px-6 py-4 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 && (
                                data.map((data, Index) => (
                                    <tr key={Index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td class="px-2 sm:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {data.cusName}
                                        </td>
                                        <td class="sm:px-6 py-4">
                                            {data.cusAttentionTo}
                                        </td>
                                        <td class="px-2 sm:px-6 py-4">
                                            {data.cusContactNumer}
                                        </td>
                                        <td class="px-2 sm:px-6 py-4">
                                            {data.cusContactEmail}
                                        </td>
                                        <td class="px-2 sm:px-6 py-4">
                                            {data.cusTRN}
                                        </td>
                                        <td class="px-2 sm:px-6 py-4">
                                            {data.createdBy}
                                        </td>
                                        <td class="px-2 sm:px-6 py-4">
                                            {data.createdBy}
                                        </td>
                                        <td className="px-2 sm:px-6 py-4 text-center">
                                            <a href="#" className="text-blue-500 mx-2 hover:text-blue-700"
                                                onClick={() => Edit(data.cusId)}>
                                                <svg
                                                    className="w-4 h-4 inline-block align-text-bottom"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    ></path>
                                                </svg>                                                
                                                Edit
                                            </a>
                                            <a href="#" className="text-green-500 mx-2 hover:text-green-700">
                                                <svg
                                                    className="w-4 h-4 inline-block align-text-bottom"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M5 13l4 4L19 7"
                                                    ></path>
                                                </svg>
                                                View
                                            </a>                                            
                                        </td>

                                    </tr>
                                ))
                            )}

                        </tbody>
                    </table>
                    <div class="fixed bottom-4 right-4">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                            onClick={() => AddNew()}
                        >
                            +
                        </button>
                    </div>
                </section>
            </div>
        ) : (<div><CustomerAdd setIsMoodAdding={setIsMoodAdding} CustomerId={CustomerId} /></div>)
    )
}

export default Customer;
