import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';


export const GetQuotationRef = createAsyncThunk('quotation/GetQuotationRef', async () => {
    const response = await axios.post(`${API_BASE_URL}quotation/GetRef`, {});
    return response.data;
});


const quotationRefSlice = createSlice({
    name: 'quotationRef',
    initialState: { quotationRef: '', loading: 'idle', error: null },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetQuotationRef.pending, (state) => {
            state.loading = "loading"
        })
            .addCase(GetQuotationRef.fulfilled, (state, action) => {
                state.loading = "succeeded"
                state.quotationRef = action.payload;
            })
            .addCase(GetQuotationRef.rejected, (state, action) => {
                state.loading = "failed"
                state.error = action.error.message;
            });
    },
});

export default quotationRefSlice.reducer;
