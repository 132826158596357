
import './App.css';

import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthRoute from './features/route/authRoute';
import MainLayout from './features/layout/form/layout';
import PrivateRoute from './features/route/PrivateRoute';
import Employee from './features/Employee/from/employee';
import User from './features/user/form/user';
import Customer from './features/customer/form/customer';
import EmployeeAdd from './features/Employee/from/employeeAdd';
import EmpAttendance from './features/attendance/forms/EmpAttendance';
import AttendanceAdd from './features/attendance/forms/attendanceAdd';
import Department from './features/department/forms/department';
import Designation from './features/designation/forms/designation';
import Quotation from './features/quotation/forms/quotation';
import QuotationAdd from './features/quotation/forms/quotationAdd';
import PDFGenerator from './features/quotation/forms/pdfGenerator';
import ProductType from './features/prodcutType/form/productType';
import ProductTypeAdd from './features/prodcutType/form/productTypeAdd';
import Project from './features/Project/forms/project';
import ProjectPDF from './features/Project/forms/projectPDF';
import PDFViewerComponent from './features/pdfDesigner/forms/PDFViewerComponent';
import Invoice from './features/invoice/form/invoice';
import InvoieAdd from './features/invoice/form/invoieAdd';
import InvoiceEdit from './features/invoice/form/InvoiceEdit';
import InvoiceList from './features/invoice/form/invoiceList';
import ProjectData from './features/Project/forms/ProjectData';
import ProjectDT from './features/Project/forms/ProjectDT';
import QuotationEdit from './features/quotation/forms/quotationEdit';


function App() {

  const [isAuthenticated, setAuthenticated] = useState(false);
  const isAuth = useSelector((state) => state.Auth.Auth);

  useEffect(() =>{
    setAuthenticated(isAuth);
  },[isAuth])
  
 // console.log(isAuth);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Public routes */}
          {!isAuthenticated && (
            <Route
              path="/"
              element={<AuthRoute />}
            >
              <Route path="login" element={<AuthRoute />} />
            </Route>
          )}

          {/* Private routes with MainLayout applied only when isAuthenticated is true */}
          {isAuthenticated && (
            <Route
              path="/*"
              element={<MainLayout />}
            >
              <Route index element={<User />} />
              <Route path="employee"
                element={<PrivateRoute element={<Employee />} isAuthenticated={isAuthenticated} />} />
              <Route path="employeeAdd"
                element={<PrivateRoute element={<EmployeeAdd />} isAuthenticated={isAuthenticated} />} />
                <Route path="employeeAdd/:employeeId" element={<PrivateRoute element={<EmployeeAdd />}
                 isAuthenticated={isAuthenticated} />} />
              <Route path="Users"
                element={<PrivateRoute element={<User />} isAuthenticated={isAuthenticated} />}
              />
              <Route path="customer"
                element={<PrivateRoute element={<Customer />} isAuthenticated={isAuthenticated} />}
              />
               <Route path="EmpAttendance"
                element={<PrivateRoute element={<EmpAttendance />} isAuthenticated={isAuthenticated} />}
              />
               <Route path="AttendanceAdd/:attendanceId"
                element={<PrivateRoute element={<AttendanceAdd />} isAuthenticated={isAuthenticated} />}
              />
              <Route path="AttendanceAdd"
                element={<PrivateRoute element={<AttendanceAdd />} isAuthenticated={isAuthenticated} />}
              />
              <Route path="department"
              element={<PrivateRoute element={<Department />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="designation"
              element={<PrivateRoute element={<Designation />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="quotation"
              element={<PrivateRoute element={<Quotation />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="Project"
              element={<PrivateRoute element={<ProjectDT />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="MyDocument"
              element={<PrivateRoute element={<PDFViewerComponent />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="ProjectPrint/:ProjectId"
              element={<PrivateRoute element={<ProjectPDF />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="QuotationAdd/:QuotId"
              element={<PrivateRoute element={<QuotationAdd />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="QuotationEdit/:QuotId"
              element={<PrivateRoute element={<QuotationEdit />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="QuotationPrint/:QuotId"
              element={<PrivateRoute element={<PDFGenerator />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="productType"
              element={<PrivateRoute element={<ProductType />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="ProductTypeAdd/:ProductId"
              element={<PrivateRoute element={<ProductTypeAdd />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="Invoice"
              element={<PrivateRoute element={<Invoice />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="InvoiceAdd/:invoiceId"
              element={<PrivateRoute element={<InvoieAdd />} isAuthenticated={isAuthenticated} />}
            />
              <Route path="InvoiceEdit/:invoiceId"
              element={<PrivateRoute element={<InvoiceEdit />} isAuthenticated={isAuthenticated} />}
            />
            </Route>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
