import React, { useEffect, useState } from 'react'

import '../css/Qotation.css'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import API_BASE_URL from '../../../apiConfig';
import { useNavigate } from 'react-router-dom';
import { fetchQuotations, CreateRevision, DeleteQuotation } from '../../../redux/Slice/quotationSlice';
import { useDispatch, useSelector } from 'react-redux';
import CreateFromQuot from '../../Project/forms/createFromQuot';
import { CreateProject, GetProjectRef } from '../../../redux/Slice/projectSlice';

const Quotation = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quotations, setquotations] = useState([]);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const { quotation, error } = useSelector((state) => state.quotation);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [Data, setData] = useState([]);
  const [pageSize, setpageSize] = useState(10);
  const [pageNumber, setpageNumber] = useState(1);


  const openProjectModal = () => {   
    setIsProjectModalOpen(true);
  };

  const closeModal = () => {
    setpojectMasterVM((prev) => {
      return {
        ...prev,
        QuotId: 0,
        PorjectRefNo: ''
      }
    });

    setIsProjectModalOpen(false);
  };


  useEffect(() => {
    setquotations(quotation);    
  }, [quotation])
 

  const AddNew = () => {    
    navigate("/QuotationAdd/0")
  }
  

  const [pojectMasterVM, setpojectMasterVM] = useState({
    QuotId: 0,
    ProjectName: '',
    ScopeOfWork: '',
    PorjectRefNo: '',
    PlanStartDate : '',
    PlanFinishDate : '',
    ActualStartDate : ''
  })

  const handleProjectChange = (event) => {
    const { name, value } = event.target;
    setpojectMasterVM((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const CreateNewRevision = async (Id) => {
    const CNR = await dispatch(CreateRevision(Id));
    // await dispatch(fetchQuotations());
    if (parseInt(CNR.payload) > 0) {
      navigate(`/QuotationPrint/${CNR.payload}`)
    }
  }

  const Convert2Project = async (row) => {
    var newRefNum = await dispatch(GetProjectRef());
    let newProjectRef = newRefNum.payload.toString()
    // setpojectMasterVM((prev) => {
    //   return {
    //     ...prev,
    //     QuotId: row.quotId,
    //     PorjectRefNo: newProjectRef
    //   }
    // });

    var QuotId = row.quotId;
    
    handleProjectChange({ target: { name: 'QuotId', value:QuotId } });
    handleProjectChange({ target: { name: 'PorjectRefNo', value: newProjectRef } });
    
    openProjectModal();
  }

  const [SaveProjectText, setSaveProjectText] = useState('Save');

  const handleAddproject = async () => {
    if (pojectMasterVM.QuotId > 0) {
      setSaveProjectText('Please wait system is working');
      var response = await dispatch(CreateProject(pojectMasterVM));
      if (parseInt(response.payload) > 0) {
        navigate(`/ProjectPrint/${parseInt(response.payload)}`)
        setSaveProjectText('Save');
      }
      else {
        setSaveProjectText('Save');
        alert("something went wrong");
      }
    }
    else {
      alert("Quotation Id is not selected");
    }
  }

const [isDeleteOpen, setIsDeleteOpen]= useState(false);
const [DeleteId, setDeleteId]= useState(0);

const ConfirmDelete = (Id) => {
  setDeleteId(Id); 
  setIsDeleteOpen(true);
}


const DeleteQuot = () => {   
  if (parseInt(DeleteId) > 0) {
    var response = dispatch(DeleteQuotation(DeleteId));
    setIsDeleteOpen(false);
    console.log(response);
  } else {
    alert("No ID selected");
  }
}
  

  const handleEdit= (row) => {
    navigate("/QuotationEdit/"+row.quotId)
  }
 

  const columns = [        
    {
      name: 'quotation Date',   
    selector: row => row.quotDate,
    sortable: true,
    format: row => {
      // Format the invoice date
      return new Date(row.quotDate).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
      
    },  
    {
      name: 'Ref No',
      selector: row => row.refNo,
      sortable: true,
      
    },
    {
      name: 'Revision',
      selector: row => row.revNo,
      sortable: true,
      
    },
    {
      name: 'Customer',
      selector: row => row.customer === null ? " " : row.customer.cusName,
      sortable: true,
      
    },
    {
      name: 'Sales',
      selector: row => row.employee === null ? " " : row.employee.firstName + ' ' +row.employee.lastName,
      sortable: true,
      
    },      
    {
      name: 'Grand Total',
      selector: row => row.grandTotal,
      sortable: true,
      
    },
    {
      name: 'Actions',
      cell: row => (
        <div class="inline-flex">
          <span href="#" className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject ? 'disabled' : ''}`}  title='Edit'
            onClick={() => handleEdit(row)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </span>
          | 
          <span href="#"  className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject ? 'disabled' : ''}`} title='Convert to Project'
            onClick={() => Convert2Project(row)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={"w-5 h-5"}>
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
        </span>
        |
          <span href="#" className="text-blue-500 mx-2 hover:text-blue-700 cursor-pointer" title='View'
            onClick={() => navigate(`/QuotationPrint/${row.quotId}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </span>
          
           |
        <span href="#" className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject ? 'disabled' : ''}`} title='Delete'
            onClick={() => ConfirmDelete(row.quotId)}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
        </span>
        |
          <span href="#" className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject || row.isLock ? 'disabled' : ''}`} title='Create new Revision'
            onClick={() => CreateNewRevision(row.quotId)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
            </svg>  
          </span>

          {/* Add more buttons as needed */}
        </div>
      )
    }

  ];

  const onChangePage = (page) => {
    setpageNumber(page);
}

useEffect(()=>{
  LoadData(pageNumber, pageSize);
},[pageNumber, pageSize])


const handleRowsPerPageChange = (newPerPage, page) => {    
  setpageSize(newPerPage);   
};
const CustomTitle = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>Quotations</div>
      <button type='button' className={AddNew === 'none' ? "hidden bg-blue-500 hover:bg-blue-700 text-white px-4" : "bg-blue-500 hover:bg-blue-700 text-white px-4"} onClick={AddNew}>Add New</button>
  </div>
);

const LoadData = async (page, PS) => {
  setLoading(true);
  try {
      const response = await axios.post(`${API_BASE_URL}quotation/All`, { "page": page, "pageSize": PS });               
      const { quotations, totalCount } = response.data;      
      setData(quotations);
      setTotalRows(totalCount);
      setLoading(false);
  } catch (error) {
      console.error('Error loading data:', error);
  }        
}


  if (loading === 'loading') {
    return <p>Loading...</p>;
  }

  if (loading === 'failed') {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {quotations && (
         <div hidden={isProjectModalOpen} >
          <DataTable
            title={<CustomTitle />}
            columns={columns}
            data={Data}
            progressPending={loading}
            paginationRowsPerPageOptions={[5, 10, 20, 30]} 
            pagination={true}
            fixedHeader={true}
            paginationServer={true}
            //selectableRows={true}
            paginationTotalRows={totalRows}
            paginationPerPage={pageSize}
             onChangePage={onChangePage}
             onChangeRowsPerPage={handleRowsPerPageChange}
        />
         </div>
      )}
      <div>
        <CreateFromQuot isOpen={isProjectModalOpen} onClose={closeModal}>
          <h1 className="text-xl font-bold mb-4">Project Description</h1>
          <hr />
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Project Ref #:
            </label>
            <label class="h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem]">{pojectMasterVM.PorjectRefNo}</label>
          </div>
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Project Name:
            </label>
            <textarea placeholder='Please enter  Project Name'
              class="bg-white h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none 
                    focus:shadow-outline bg-gray-50 text-[0.8rem] h-[70%]"
              name='ProjectName'
              id='ProjectName'
              onChange={handleProjectChange}
            >
            </textarea>
          </div>
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Scope of work:
            </label>
            <textarea placeholder='plase enter Scope of work...'
              class="bg-white h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem] h-[70%]"
              name='ScopeOfWork'
              id='ScopeOfWork'
              onChange={handleProjectChange}
            >
            </textarea>
          </div>
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Plan Start Date:
            </label>
            <input type='date' 
              class="bg-white h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem] h-[70%]"
              name='PlanStartDate'
              id='PlanStartDate'
              onChange={handleProjectChange}
           />           
          </div>
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Plan Finish Date:
            </label>
            <input type='date' 
              class="bg-white h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem] h-[70%]"
              name='PlanFinishDate'
              id='PlanFinishDate'
              onChange={handleProjectChange}
           />           
          </div>
          <div class="lg:col-span-2 text-left flex items-center whitespace-nowrap mt-3">
            <label class="!bg-transparent block font-bold font-normal mr-8 text-gray-700 text-[0.8rem]" htmlFor="Customer">
              Actual Start Date:
            </label>
            <input type='date' 
              class="bg-white h-8 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline bg-gray-50 text-[0.8rem] h-[70%]"
              name='ActualStartDate'
              id='ActualStartDate'
              onChange={handleProjectChange}
           />           
          </div>
          <hr className='mt-3' />
          <div className='mt-3 text-right'>
            <button type='button' class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              onClick={() => handleAddproject()}
              disabled={SaveProjectText === 'Save' ? false : true}
            >
              {SaveProjectText}
            </button>
            <button type='button' class="ml-2 bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              onClick={() => closeModal()}
              disabled={SaveProjectText === 'Save' ? false : true}
            >
              Cancel
            </button>
          </div>
        </CreateFromQuot>

        <div hidden={!isDeleteOpen}>
          <div id="modal" class="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div class="bg-white p-8 rounded shadow-lg w-96">
              <p class="text-lg font-semibold mb-4">Are you sure you want to delete this quotation?</p>
              <div class="flex justify-between">
                <button type='button' onClick={DeleteQuot} class="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded mr-2">Delete</button>
                <button type='button' onClick={() => setIsDeleteOpen(false)} class="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quotation;