import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AuthinticateUser } from '../../redux/Slice/userLogin';
import { login, logout } from '../../redux/Slice/appSlice'

const AuthRoute = () => {

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.Login);
  const [validateInput, setValidate] = useState('')
  const [userLogin, setUserLogin] = useState({
    Username : '',
    Password : ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserLogin((prevUserLogin) => ({
      ...prevUserLogin,
      [name]: value
    }));
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data).length !== 0) {
      dispatch(login())
    } else {      
      dispatch(logout())
    }
  }, [data,dispatch]);


  if(loading === "loading")
  {
    <div>Loading</div>
  }

  if(loading === "failed")
  {
    <div>{error}</div>
  }

  if(loading === "succeeded")
  {
    <div>succeeded</div>
  }
  

  const onKeyDown = (event) => {    
    if (event.key === 'Enter') {      
      handleSubmit();
    }
  };

  const handleSubmit = async () =>{
    if(userLogin.Username === '' || userLogin.Username === undefined)
    {
      setValidate("Please provide Username");
    }
    else if(userLogin.Password === '' || userLogin.Password === undefined)
    {
      setValidate("Please provide Password");
    }   
    else
    {
      var result = await  dispatch(AuthinticateUser(userLogin));  
      const checkSuccess = result.type.split('/')[2];
      if(checkSuccess === 'rejected')
      {
        setValidate("UserName or Password incorrect");       
      }      
    }
  }

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-semibold mb-6 text-blue-600">User Login</h2>
        
          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2" htmlFor="username">
              Username
            </label>
            <input
              className="border border-gray-300 text-gray-900 p-2 w-full rounded focus:outline-none focus:border-blue-500"
              type="text"
              id="username"
              name="Username"
              placeholder="Your username"             
              required         
              value={userLogin.Username}    
              onChange={handleChange}             
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-600 text-sm font-medium mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="border border-gray-300  text-gray-900 p-2 w-full rounded focus:outline-none focus:border-blue-500"
              type="password"
              id="password"
              name="Password"
              placeholder="Your password"
              required     
              value={userLogin.Password}    
              onChange={handleChange}         
              onKeyDown={onKeyDown}
            />
          </div>
          <label
            className={`block text-sm font-medium mb-2 text-red-500`}
            htmlFor="validateInput"
          >
            {validateInput}
          </label>
          <button
            className="bg-blue-500 text-white p-2 w-full rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2"
            type="submit"
            onClick={handleSubmit}
          >
            Login
          </button>
        
      </div>
    </div>
  )
}

export default AuthRoute;