import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import API_BASE_URL from '../../../apiConfig';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProjectDT = () => {

  const navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);
    const [Data, setData] = useState([]);
    const [pageSize, setpageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [loading, setLoading] = useState(false);

    const AddNew = () => {
        console.log("AddNew");
    }


    const CustomTitle = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>Projects</div>
            <button type='button' className={AddNew === 'none' ? "hidden bg-blue-500 hover:bg-blue-700 text-white px-4" : "bg-blue-500 hover:bg-blue-700 text-white px-4"} onClick={AddNew}>Add New</button>
        </div>
    );

    // useEffect(() => {
    //     LoadData(pageNumber, pageSize);
    // }, [])

    const handleRowsPerPageChange = (newPerPage, page) => {    
        setpageSize(newPerPage);   
      };


    const LoadData = async (page, PS) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_BASE_URL}Project/All`, { "page": page, "pageSize": PS });               
            const { projects, totalCount } = response.data;
            setData(projects);
            setTotalRows(totalCount);
            setLoading(false);
        } catch (error) {
            console.error('Error loading data:', error);
        }        
    }

    const onChangePage = (page) => {
        setpageNumber(page);
    }

    useEffect(()=>{
        LoadData(pageNumber, pageSize);
    },[pageNumber, pageSize])


    const columns = [        
        {
          name: 'Project Date',
        selector: row => row.projectDate,
        sortable: true,
        format: row => {
          // Format the invoice date
          return new Date(row.projectDate).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
        }
          
        },  
        {
          name: 'project Name',
          selector: row => row.projectName,
          sortable: true,
          
        },
        {
          name: 'Ref No',
          selector: row => row.porjectRefNo,
          sortable: true,
          
        },
        {
          name: 'Quotation/Revision',
          selector: row => row.quotationRefNo,
          sortable: true,
          
        },
        {
          name: 'Customer',
          selector: row => row.customer.cusName,
          sortable: true,
          
        },
        {
          name: 'Sales',
          selector: row => row.employee.firstName + ' ' +row.employee.lastName,
          sortable: true,
          
        },      
        {
          name: 'Grand Total',
          selector: row => row.grandTotal,
          sortable: true,
          
        },
        {
          name: 'Actions',
          cell: row => (
            <div class="inline-flex">
              <span href="#" className="text-blue-500 mx-2 hover:text-blue-700 cursor-pointer" title='Edit'
                onClick={() => AddNew()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </span>
              | 
              <span href="#" className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject ? 'disabled' : ''}`} title='Convert to invoice'
                 onClick={() => AddNew()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={"w-5 h-5"}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                </svg>
              </span>
              |
          <span href="#" className="text-blue-500 mx-2 hover:text-blue-700 cursor-pointer" title='View'
            onClick={() => navigate(`/ProjectPrint/${row.projectId}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </span>
          |
        <span href="#" className={`text-blue-500 mx-2 hover:text-blue-700 cursor-pointer ${row.isConvertedToProject ? 'disabled' : ''}`} title='Delete'
            onClick={() => AddNew()}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
        </span>
              </div>
          )
        }
        
      ];


    return (
        <DataTable
            title={<CustomTitle />}
            columns={columns}
            data={Data}
            progressPending={loading}
            paginationRowsPerPageOptions={[5, 10, 20, 30]} 
            pagination={true}
            fixedHeader={true}
            paginationServer={true}
            //selectableRows={true}
            paginationTotalRows={totalRows}
            paginationPerPage={pageSize}
             onChangePage={onChangePage}
             onChangeRowsPerPage={handleRowsPerPageChange}
        />
    );
};

export default ProjectDT;
